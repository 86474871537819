import { fetcher } from 'src/api/fetch';
import { ApiErrorShape } from 'src/api/types';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestParams {
  phone: string;
  otp: string;
}

interface RequestResult {
  errors: ApiErrorShape[];
}

const requestPostConfirmPasswordRecovery = async (params: RequestParams): Promise<RequestResult | null> => {
  const { otp, phone } = params;
  const bodyData = JSON.stringify({ phone, otp });

  const response = await fetcher(`/user/confirm_password_recovery/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return getErrorsFromResponse(response);
};

export default requestPostConfirmPasswordRecovery;
