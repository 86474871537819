import { ReactNode } from 'react';
import useThemeOld from '../../services/theme/useThemeOld';
import IconFont from '../../ui-kit/IconFont';
import getStyles from './NotificationBar.styles';

interface Props {
  dataMarker?: string;
  children: ReactNode;
}

function NotificationBar(props: Props) {
  const { children, dataMarker } = props;
  const theme = useThemeOld();
  const styles = getStyles(theme);

  return (
    <p className='NotificationBar' data-marker={dataMarker} role='alert'>
      <IconFont name='info' size='13px' color='dark' sbs={{ mr: '8px' }} />
      {children}

      <style jsx>{styles}</style>
    </p>
  );
}

export default NotificationBar;
