import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import ForgotLoginModal from '../authorizationModal/components/ForgotLoginModal/ForgotLoginModal';
import useModals from './useModals';

const AddressManagementModal = dynamic(
  () => import('../address-management/components/AddressManagementModal/AddressManagementModal'),
  { ssr: false },
);

const AuthorizationModal = dynamic(
  () => import('../authorizationModal/components/AuthorizationModal/AuthorizationModal'),
  { ssr: false },
);
const RegistrationSuccessModal = dynamic(
  () => import('../authorizationModal/components/RegistrationSuccessModal/RegistrationSuccessModal'),
  { ssr: false },
);

export default function ModalsList() {
  const { state } = useModals();

  return (
    <Fragment>
      {state.addressManagement.isOpen && <AddressManagementModal />}
      {state.authorizationModal.isOpen && <AuthorizationModal />}
      {state.forgotLoginModal.isOpen && <ForgotLoginModal />}
      {state.registrationSuccessModal.isOpen && <RegistrationSuccessModal />}
    </Fragment>
  );
}
