import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .PasswordInput {
      position: relative;
    }
    .PasswordInput__button {
      position: absolute;
      right: 15px;
      top: 28px;
    }
  `;
};

export default getStyles;
