import typography from 'src/styles/typography';
import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .Input {
      height: 40px;
      width: 100%;
      padding: 8px 16px;
      border: 1px solid ${theme.base.color.gray300};
      border-radius: ${theme.base.borderRadii.v1};
      font-size: 16px;
      line-height: 24px;
      color: ${theme.base.color.dark}
    }

    .Input::placeholder {
      color: ${theme.base.color.gray400};
    }

    .Input:disabled {
      color: ${theme.base.color.gray500};
      background-color: ${theme.base.color.gray100};
    }

    .Input_error {
      border-color: ${theme.base.color.warning};
    }

    .Input__errorMessage {
      margin-top: 4px;
      ${typography.error};
    }

    .Input:focus {
      border-color: ${theme.base.color.gray500};
      outline: none;
    }

    .Input[type='number']::-webkit-inner-spin-button,
    .Input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .Input[type='number'] {
      -moz-appearance: textfield;
    }
  `;
};

export default getStyles;
