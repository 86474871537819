import { ReactNode, useEffect } from 'react';
import useUserGeolocation from 'src/modules/address-management/hooks/useUserGeolocation/useUserGeolocation';
import useDeliveryServices from 'src/modules/delivery/hooks/useDeliveryServices/useDeliveryServices';
import { useLocalize } from '../../../../services/localize/useLocalize';
import useUserDelivery from '../../../../services/userDelivery/useUserDelivery';
import { getDeliveryAddressText } from '../../helpers/getDeliveryAddressText';
import { getDeliveryTypeText } from '../../helpers/getDeliveryTypeText';
import addressManagementPreparerContext, { AddressManagementPreparerContext } from './addressManagementPreparerContext';

export interface Props {
  children: ReactNode;
  language: string;
}

export default function AddressManagementPreparerProvider(props: Props) {
  const { children, language } = props;
  const localize = useLocalize();
  const { deliveryServices, status: deliveryServicesStatus } = useDeliveryServices(language);
  const { userDelivery, userDeliveryIsReady, updateUserDelivery } = useUserDelivery();
  const { geoIsAvailable, geoCoords, updateGeoCoords } = useUserGeolocation();

  useEffect(() => {
    if (userDeliveryIsReady && userDelivery === null) {
      updateGeoCoords();
    }
  }, [updateGeoCoords, userDeliveryIsReady, userDelivery]);

  const addressManagementIsReady = deliveryServicesStatus === 'succeeded' && userDeliveryIsReady;

  const contextValue: AddressManagementPreparerContext = {
    addressManagementIsReady,
    deliveryServices,
    userDelivery,
    updateUserDelivery,
    geoCoords,
    geoIsAvailable,
    updateGeoCoords,
    amBarProps: {
      skeletonIsDisplayed: !addressManagementIsReady,
      deliveryTypeText: getDeliveryTypeText(userDelivery, localize),
      deliveryAddressText: getDeliveryAddressText(userDelivery, deliveryServices, localize),
    },
  };

  return (
    <addressManagementPreparerContext.Provider value={contextValue}>
      {children}
    </addressManagementPreparerContext.Provider>
  );
}
