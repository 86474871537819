const getSegmentInfo = (path: string) => {
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const segments = uri.split('/').filter((s) => !!s);
  const langSegment = segments[0];

  return {
    segments,
    langSegment,
    params,
  };
};

export default getSegmentInfo;
