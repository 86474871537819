import { phone, tablet_desktop } from 'src/styles/media';
import { ThemeOld } from 'src/styles/theme-old';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const getStyles = (theme: ThemeOld) => {
  return css`
  .ConfirmPhoneByCode {
    font-size: 14px;
    text-align: left;
  }
  .ConfirmPhoneByCode__title {
    line-height: 1.5;
  }
  .ConfirmPhoneByCode__timeInterval,
  .ConfirmPhoneByCode__titlePhone {
    ${typography.h6}
    white-space: nowrap;
  }
  .ConfirmPhoneByCode__timeInterval {
    font-weight: normal;
  }
  .ConfirmPhoneByCode__inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .ConfirmPhoneByCode__editPhone {
    margin-bottom: 24px;
    margin-top: 20px;
  }
  .ConfirmPhoneByCode__inputs :global(input) {
    border-color: #ddd;
    text-align: center;
    -moz-appearance: textfield;
  }
  .ConfirmPhoneByCode__edit {
    display: block;
    line-height: 1.5;
  }
  .ConfirmPhoneByCode__sms span {
    font-size: 12px;
    color: ${theme.colors.mineShaft};

  }
  .ConfirmPhoneByCode__buttons {
    margin-top: 39px;
  }
  .ConfirmPhoneByCode__error {
    margin-top: 4px;
    text-align: left;
    font-size: 12px;
    color: ${theme.colors.red};
  }
  .ConfirmPhoneByCode__countdown {
    margin-top: 21px;
  }

  @media ${phone} {
    .ConfirmPhoneByCode__title {
      margin-bottom: 20px;
      text-align: left;
    }
    .ConfirmPhoneByCode__edit {
      margin-bottom: 30px;
      font-size: 14px;
    }
  }

  @media ${tablet_desktop} {
    .ConfirmPhoneByCode__title {
      margin-bottom: 10px;
    }
    .ConfirmPhoneByCode__edit {
      margin-bottom: 20px;
    }
  }
  `;
};
