import { LANGUAGES } from 'src/data/constants';
import { PathHandler } from '../getRedirectPath';
import getSegmentInfo from '../helpers/getSegmentInfo';

const handlePreferredLanguage: PathHandler = (ctx) => {
  const { path, preferredLanguage } = ctx;

  if (!preferredLanguage) {
    return { path };
  }

  const { segments, params, langSegment } = getSegmentInfo(path);
  const paramsPart = params ? `?${params}` : '';

  if (
    preferredLanguage !== langSegment &&
    LANGUAGES.includes(preferredLanguage)
  ) {
    const [, ...restSegments] = segments;
    const newPath = ['', preferredLanguage, ...restSegments, ''].join('/') + paramsPart;

    return { path: newPath, redirectType: 302 };
  }

  return { path };
};

export default handlePreferredLanguage;
