var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"BgS3xl4Pm9whTWfuYhOsB"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from './src/data/constants';

Sentry.init({
  dsn: SENTRY_DSN,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  tracesSampleRate: 0.01,
  allowUrls: [
    /https?:\/\/zakaz\.ua/,
  ],
  ignoreErrors: [
    'Failed to fetch', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127423/
    'отменено', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127785/
    'cancelled', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127761/
    'NetworkError when attempting to fetch resource', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127802/
    'скасовано', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127769/
    'AbortError: The operation was aborted', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127751/
    'Превышен лимит времени на запрос', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/127763/
    'The request timed out', // https://sentry.zakaz.ua/zakaz/zakaz-mainsite-web/issues/130496/
    'Network request failed', // https://sentry.zakaz.global/organizations/zakaz/issues/138322/?project=54
    'Cannot find function isEqualNode in object', // https://caniuse.com/mdn-api_node_isequalnode
    'Abgebrochen', // https://sentry.zakaz.global/organizations/zakaz/issues/128507/?project=54
    'illegal character U+009E', // https://sentry.zakaz.global/organizations/zakaz/issues/128957/?project=54
    'Non-Error promise rejection captured with value', // https://sentry.zakaz.global/organizations/zakaz/issues/139464/?project=54
  ],
});
