import { useEffect, useMemo, useState } from 'react';
import { RetailChain } from '../../../api/types';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { Delivery } from '../../delivery/types';
import getRetailInfoLists from '../helpers/getRetailInfoLists';
import getRetailInfoQueryStringForLink from '../helpers/getRetailInfoQueryStringForLink';
import { getRetailsInfoRequestQueryString } from '../helpers/getRetailsInfoRequestQueryString';
import { requestGetRetailChains } from '../helpers/requestGetRetailChains';
import { requestGetUserRetails } from '../helpers/requestGetUserRetails';
import { sendChainCardShown } from '../helpers/retailsInfoAnalytics';
import { RetailInfo } from '../types';

export interface useRetailsInfoLogicProps {
  userDelivery: Delivery | null;
  userDeliveryIsReady: boolean;
  language: string;
  localize: LocalizeFunc;
}

const useRetailsInfoLogic = (props: useRetailsInfoLogicProps) => {
  const {
    userDelivery,
    userDeliveryIsReady,
    language,
    localize,
  } = props;

  const [retailChains, setRetailChains] = useState<RetailChain[] | null>(null);
  const [retailInfoLists, setRetailInfoLists] = useState<{
    available: RetailInfo[];
    unavailable: RetailInfo[];
  }>({ available: [], unavailable: [] });
  const [retailListsAreLoading, setRetailListsAreLoading] = useState<boolean>(false);

  const deliveryAsQueryStringForRequest = useMemo(() => {
    return getRetailsInfoRequestQueryString(userDelivery);
  }, [userDelivery]);

  const deliveryAsQueryStringForLink = useMemo(() => {
    return getRetailInfoQueryStringForLink(userDelivery);
  }, [userDelivery]);

  const deliveryType = userDelivery?.type || null;

  useEffect(() => {
    (async () => {
      const newRetailChains = await requestGetRetailChains();

      setRetailChains(newRetailChains);
    })();
  }, []);

  useEffect(() => {
    if (userDeliveryIsReady && retailChains) {
      (async () => {
        setRetailListsAreLoading(true);

        const userRetails = await requestGetUserRetails(
          deliveryType,
          deliveryAsQueryStringForRequest,
          language,
        );

        const newRetailInfoLists = getRetailInfoLists({
          retailChains,
          userRetails,
          deliveryType,
          deliveryAsQueryStringForLink,
          language,
          localize,
        });

        setRetailInfoLists(newRetailInfoLists);
        setRetailListsAreLoading(false);

        const availableCardsCount = newRetailInfoLists.available.length;
        const totalCardsCount = availableCardsCount + newRetailInfoLists.unavailable.length;
        sendChainCardShown(totalCardsCount, availableCardsCount);
      })();
    }
  }, [
    userDeliveryIsReady,
    deliveryType,
    deliveryAsQueryStringForRequest,
    deliveryAsQueryStringForLink,
    language,
    retailChains,
    localize,
  ]);

  const skeletonIsDisplayed = retailInfoLists.available.length === 0 && retailInfoLists.unavailable.length === 0;

  return {
    retailInfoLists,
    skeletonIsDisplayed,
    retailListsAreLoading,
  };
};

export default useRetailsInfoLogic;
