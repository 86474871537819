import { desktop, tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .RetailsInfo {
      position: relative;
      margin-top: 12px;
    }

    .RetailsInfo__list {
      margin-bottom: 32px;
    }

    .RetailsInfo_isLoading .RetailsInfo__wrapContent {
      opacity: 0.56;
    }

    .RetailsInfo_isLoading .RetailsInfo__loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media ${tablet} {
      .RetailsInfo {
        margin-top: 12px;
      }
    }

    @media ${desktop} {
      .RetailsInfo {
        margin-top: 16px;
      }
    }
  `;
};

export default getStyles;
