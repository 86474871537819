import { css } from '@emotion/css';
import { StylishBoxStyles } from '../types';
import { getResultEmotionStyles } from './getResultEmotionStyles';

const sbsToClassName = (sbs: StylishBoxStyles | undefined): string => {
  if (!sbs) {
    return '';
  }

  return css(getResultEmotionStyles(sbs));
};

export default sbsToClassName;
