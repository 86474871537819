import css from 'styled-jsx/css';
import { ThemeOld } from './theme-old';

const getStyles = (theme: ThemeOld) => {
  return css.global`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    .grecaptcha-badge {
      opacity: 0;
      pointer-events: none;
      z-index: 100;
    }

    body {
      font: 16px / 1 ${theme.fonts.default};
      color: ${theme.colors.text};
    }

    a {
      text-decoration: none;
    }
  `;
};

export default getStyles;
