import { fetcher } from 'src/api/fetch';
import { RetailChain } from 'src/api/types';
import { getChainsUrl } from 'src/api/urls';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';

export const requestGetRetailChains = async (): Promise<RetailChain[]> => {
  const response = await fetcher<RetailChain[]>(getChainsUrl());

  return getDataFromResponse(response, []);
};
