import { css, CSSInterpolation } from '@emotion/css';
import { Theme } from '../theme/types';
import { StylishLinkVariant } from './StylishLink';

const baseStyles: CSSInterpolation = {
  transition: 'all 0.3s',
};

const getClassNameStyles = (variant: StylishLinkVariant, theme: Theme) => {
  const themeVariant = theme.stylishLink[variant];

  const variantStyles: CSSInterpolation = {
    '&&': {
      color: themeVariant.color,
      textDecoration: themeVariant.textDecoration,
      '&:hover': {
        color: themeVariant.colorHover,
        textDecoration: themeVariant.textDecorationHover,
      },
      '&:active': {
        color: themeVariant.colorActive,
      },
    },
  };

  return css([
    baseStyles,
    variantStyles,
  ]);
};

export default getClassNameStyles;
