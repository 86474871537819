import { ComponentType, useEffect } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

function withRecaptcha<P extends object>(WrappedComponent: ComponentType<P>) {
  const WithRecaptchaComponent = (props: P) => {
    const toggleBadgeVisibility = (isOn: boolean) => {
      const badge = document.querySelector<HTMLElement>('.grecaptcha-badge');

      if (badge) {
        badge.style.opacity = isOn ? '1' : '0';
        badge.style.pointerEvents = isOn ? 'auto' : 'none';
      }
    };

    useEffect(() => {
      toggleBadgeVisibility(true);
      return () => toggleBadgeVisibility(false);
    }, []);

    return <WrappedComponent {...props} />;
  };

  return withGoogleReCaptcha(WithRecaptchaComponent);
}

export default withRecaptcha;
