import { ThemeOld } from 'src/styles/theme-old';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

const getStyles = (theme: ThemeOld) => {
  return css`
    .AccountNavigation__user {
      padding: 0 16px;
      ${typography.text};
      line-height: 24px;
    }
    .AccountNavigation__userName {
      font-weight: 600;
    }
    .AccountNavigation__line {
      height: 1px;
      width: 100%;
      margin-top: 4px;
      background: ${theme.colors.whiteSmoke};
    }
    .AccountNavigation__link {
      background: transparent;
      border: none;
      outline: none;
      position: relative;
      display: flex;
      align-items: baseline;
      align-self: flex-start;
      padding: 0;
      width: 100%;
      text-decoration: none;
    }
    .AccountNavigation__link:not([disabled]) {
      cursor: pointer;
    }
    .AccountNavigation_dropdown .AccountNavigation__link_active,
    .AccountNavigation_dropdown .AccountNavigation__link:hover {
      background: ${theme.colors.solitude};
    }
    .AccountNavigation_account .AccountNavigation__link_active:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: ${theme.colors.mainBrand};
      transition: 0.2s all ease-in-out;
    }
    .AccountNavigation_account .AccountNavigation__logout {
      margin-top: 12px;
    }
    .AccountNavigation_account .AccountNavigation__line + .AccountNavigation__logout {
      margin-top: 0;
    }
  `;
};

export default getStyles;
