import css from 'styled-jsx/css';
import { desktop, tablet } from '../../../styles/media';

const thickness = 1;
const phoneTabletCrossIconSize = 9;
const desktopCrossIconSize = 12;
const tabletButtonSize = 40;
const phoneButtonSize = 33;


const getStyles = () => {
  return css`
    .GeneralModalCloseButton {
      z-index: 999;
      position: absolute;
      right: 10px;
      top: -${phoneButtonSize / 2}px;
      height: ${phoneButtonSize}px;
      width: ${phoneButtonSize}px;
    }

    .GeneralModalCloseButton__iconContainer {
      height: ${phoneButtonSize}px;
      width: ${phoneButtonSize}px;
      background-color: #fff;
      display: flex;
      margin: auto;
      position: fixed;
      display: flex;
      border-radius: 50%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    .GeneralModalCloseButton__icon {
      margin: auto;
      width: ${phoneTabletCrossIconSize}px;
      height: ${phoneTabletCrossIconSize}px;
      opacity: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }

    .GeneralModalCloseButton__icon:hover {
      opacity: 0.8;
    }

    .GeneralModalCloseButton__icon:before,
    .GeneralModalCloseButton__icon:after {
      position: absolute;
      content: '';
      height: ${phoneTabletCrossIconSize * 1.3}px;
      width: ${thickness}px;
      background-color: #000;
    }

    .GeneralModalCloseButton__icon:before {
      transform: rotate(45deg);
    }

    .GeneralModalCloseButton__icon:after {
      transform: rotate(-45deg);
    }

    @media ${tablet} {
      .GeneralModalCloseButton {
        right: 16px;
        top: -${tabletButtonSize / 2}px;
        height: ${tabletButtonSize}px;
        width: ${tabletButtonSize}px;
      }

      .GeneralModalCloseButton__iconContainer {
        height: ${tabletButtonSize}px;
        width: ${tabletButtonSize}px;
      }
    }

    @media ${desktop} {
      .GeneralModalCloseButton {
        right: -24px;
        top: 0;
        height: ${desktopCrossIconSize}px;
        width: ${desktopCrossIconSize}px;
      }

      .GeneralModalCloseButton__iconContainer {
        height: ${desktopCrossIconSize}px;
        width: ${desktopCrossIconSize}px;
        background-color: initial;
        box-shadow: none;
      }

      .GeneralModalCloseButton__icon:before,
      .GeneralModalCloseButton__icon:after {
        height: ${desktopCrossIconSize * 1.3}px;
        background-color: #fff;
      }
    }
  `;
};

export default getStyles;
