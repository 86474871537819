import { CSSInterpolation } from '@emotion/css';
import * as media from '../../../styles/media';
import { StylishBoxStyles } from '../types';
import divideStylesByResolutionsAndStates from './divideStylesByResolutionsAndStates';
import getResolutionStyles from './getResolutionStyles';

export function getResultEmotionStyles(
  sbs: StylishBoxStyles | undefined,
): CSSInterpolation[] {
  const {
    general, generalHover,
    desktop, desktopHover,
    desktopTablet, desktopTabletHover,
    tablet, tabletHover,
    tabletPhone, tabletPhoneHover,
    phone, phoneHover,
  } = divideStylesByResolutionsAndStates(sbs);

  const mediaDesktop = `@media ${media.desktop}`;
  const mediaDesktopTablet = `@media ${media.tablet_desktop}`;
  const mediaTablet = `@media ${media.tablet}`;
  const mediaTabletPhone = `@media ${media.phone_tablet}`;
  const mediaPhone = `@media ${media.phone}`;

  const result: CSSInterpolation[] = [];

  if (general) {
    result.push(getResolutionStyles(general));
  }

  if (generalHover) {
    result.push({ '&:hover': getResolutionStyles(generalHover) });
  }

  if (desktop) {
    result.push({ [mediaDesktop]: getResolutionStyles(desktop) });
  }

  if (desktopHover) {
    result.push({
      [mediaDesktop]: {
        '&:hover': getResolutionStyles(desktopHover),
      },
    });
  }

  if (desktopTablet) {
    result.push(
      { [mediaDesktopTablet]: getResolutionStyles(desktopTablet) });
  }

  if (desktopTabletHover) {
    result.push({
      [mediaDesktopTablet]: {
        '&:hover': getResolutionStyles(desktopTabletHover),
      },
    });
  }

  if (tablet) {
    result.push({ [mediaTablet]: getResolutionStyles(tablet) });
  }

  if (tabletHover) {
    result.push({
      [mediaTablet]: {
        '&:hover': getResolutionStyles(tabletHover),
      },
    });
  }

  if (tabletPhone) {
    result.push(
      { [mediaTabletPhone]: getResolutionStyles(tabletPhone) });
  }

  if (tabletPhoneHover) {
    result.push({
      [mediaTabletPhone]: {
        '&:hover': getResolutionStyles(tabletPhoneHover),
      },
    });
  }

  if (phone) {
    result.push({ [mediaPhone]: getResolutionStyles(phone) });
  }

  if (phoneHover) {
    result.push({
      [mediaPhone]: {
        '&:hover': getResolutionStyles(phoneHover),
      },
    });
  }

  return result;
}
