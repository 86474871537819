import css from 'styled-jsx/css';

const getStyles = (stroke: string, size: string) => {
  return css`
    .LoadingRing {
      position: relative;
      margin: 0px auto;
      width: ${size + 'px'};
      height: ${size + 'px'};
    }

    .LoadingRing:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .LoadingRing__circularLoader {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }

    .LoadingRing__loaderPath {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }

    @keyframes color {
      0% {
        stroke: ${stroke};
      }
      40% {
        stroke: ${stroke};
      }
      66% {
        stroke: ${stroke};
      }
      80%,
      90% {
        stroke: ${stroke};
      }
    }
  `;
};

export default getStyles;
