import { useEffect, useState } from 'react';
import { useLocalize } from 'src/services/localize/useLocalize';
import getCountdownFormat from 'src/utils/date/getCountdownFormat';
import { getStyles } from './CountdownMessage.styles';

interface Props {
  onEndCounting?: () => void;
  secondsStart?: number;
}

const CountdownMessage = (props: Props) => {
  const { onEndCounting, secondsStart = 0 } = props;
  const styles = getStyles();
  const localize = useLocalize();

  const [remainingSeconds, setRemainingSeconds] = useState(secondsStart);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds(count => count - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remainingSeconds === 0 && onEndCounting) {
      onEndCounting();
    }
  }, [remainingSeconds, onEndCounting]);

  return (
    <p className='CountdownMessage' data-testid='countdown_message'>
      {localize('sms_countdown_message')}
      <span className='CountdownMessage__clock'>{` ${getCountdownFormat(remainingSeconds)}`}</span>

      <style jsx>{styles}</style>
    </p>
  );
};

export default CountdownMessage;
