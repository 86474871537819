import { COUNTRY } from '../data/constants';
import { fetcher } from './fetch';

type TranslationsType = Record<string, string>;

function fetchTranslations(language: string) {
  return fetcher<TranslationsType>(`/translations/?project=zakaz${COUNTRY}`, {
    language,
  });
}

export default fetchTranslations;
