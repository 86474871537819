import { QueryString, RetailChain } from '../../../api/types';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { Delivery } from '../../delivery/types';
import { RetailInfoLists, UserRetail } from '../types';
import getRetailInfo from './getRetailInfo';

interface GetRetailInfoListsParams {
  retailChains: RetailChain[];
  userRetails: UserRetail[];
  deliveryType: Delivery['type'] | null;
  deliveryAsQueryStringForLink: QueryString;
  language: string;
  localize: LocalizeFunc;
}

const getRetailInfoLists = (params: GetRetailInfoListsParams) => {
  const {
    retailChains,
    userRetails,
    deliveryType,
    deliveryAsQueryStringForLink,
    language,
    localize,
  } = params;

  const retailChainsMap: {
    [key: string]: RetailChain;
  } = retailChains.reduce((acc, retailChain) => {
    acc[retailChain.id] = retailChain;
    return acc;
  }, {});

  return userRetails.reduce<RetailInfoLists>((acc, userRetail) => {
    const retailChain = retailChainsMap[userRetail.chain_id];
    const isAvailable = !deliveryType || userRetail.is_delivery_available;

    if (!retailChain) {
      return acc;
    }

    const retailInfo = getRetailInfo({
      isAvailable,
      retailChain,
      userRetail,
      deliveryType,
      deliveryAsQueryStringForLink,
      language,
      localize,
    });

    if (isAvailable) {
      acc.available.push(retailInfo);
    } else {
      acc.unavailable.push(retailInfo);
    }

    return acc;
  }, { available: [], unavailable: [] });
};

export default getRetailInfoLists;
