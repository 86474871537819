import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .EnterPhoneForm__button {
      margin-top: 30px;
    }

    .EnterPhoneForm__loginError {
      margin-top: 5px;
    }
  `;
};

export default getStyles;
