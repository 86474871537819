import { RetailChain, QueryString } from "src/api/types";

interface getRetailInfoLinkUrlParams {
  language: string;
  retailChain: RetailChain;
  withDeliveryData: boolean;
  deliveryAsQueryStringForLink: QueryString;
}


const getRetailInfoLinkUrl = (params: getRetailInfoLinkUrlParams) =>{
    const {
      language,
      retailChain,
      withDeliveryData,
      deliveryAsQueryStringForLink,
    } = params;

  if (withDeliveryData) {

    return `https://${retailChain.hostname}/${language}/${deliveryAsQueryStringForLink}`;
  }

  return `https://${retailChain.hostname}/${language}/`;
};

export default getRetailInfoLinkUrl;
