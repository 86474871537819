import Link from 'next/link';
import GeneralPagesLayout from 'src/layouts/GeneralPagesLayout';
import useLang from '../../services/language/useLang';
import { useLocalize } from '../../services/localize/useLocalize';
import styles from './Error.styles';

interface Props {
  buttonProps?: {
    as: string;
    to: string;
    label: string;
  };
}

const NotFoundPage = (props: Props) => {
  const lang = useLang();
  const { buttonProps } = props;
  const localize = useLocalize();

  return (
    <GeneralPagesLayout>
      <div className="Error">
        <div className="Error__imageBox">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img className="Error__image" src="/static/images/404.png" alt="404" />
        </div>
        <div className="Error__textBox">
          <div className="Error__number">404</div>
          <div className="Error__title">{localize('whoops_page_does_not_exist')}</div>
          <Link
            as={buttonProps?.as || `/${lang}/`}
            href={buttonProps?.to || '/[lang]/'}
            legacyBehavior
          >
            <a className="Error__button">
              {buttonProps?.label || localize('to_the_main')}
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{styles}</style>
    </GeneralPagesLayout>
  );
};
export default NotFoundPage;
