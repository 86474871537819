import { Delivery } from "src/modules/delivery/types";
import { RetailChain, QueryString } from '../../../api/types';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { RetailInfo, UserRetail } from "../types";
import getRetailInfoLinkUrl from './getRetailInfoLinkUrl';
import getTimeWindowText from './getTimeWindowText';

interface GetRetailInfoParams {
  isAvailable: boolean;
  retailChain: RetailChain;
  userRetail: UserRetail | null;
  deliveryType: Delivery['type'] | null;
  deliveryAsQueryStringForLink: QueryString;
  language: string;
  localize: LocalizeFunc;
}

const getRetailInfo = (params: GetRetailInfoParams): RetailInfo => {
  const {
    isAvailable,
    retailChain,
    userRetail,
    deliveryType,
    deliveryAsQueryStringForLink,
    language,
    localize,
  } = params;

  const retailChainLinkUrl = getRetailInfoLinkUrl({
    language,
    retailChain,
    withDeliveryData: isAvailable,
    deliveryAsQueryStringForLink});

  const retailInfo: RetailInfo = {
    id: retailChain.id,
    chainName: retailChain.name,
    linkUrl: retailChainLinkUrl,
    logoUrl: retailChain.logo.logo,
    cartCount: userRetail?.cart_items_count,
  };

  if (isAvailable) {
    const timeWindow = userRetail?.nearest_time_window || null;
    const timeWindowHasError = !!deliveryType && !timeWindow;

    retailInfo.timeWindowText = getTimeWindowText(timeWindow, deliveryType, localize);
    retailInfo.timeWindowHasError = timeWindowHasError;

    if (timeWindow) {
      const tooltipData = new Date(timeWindow.end_ordering_time)
        .toLocaleString(language, {
          hour: 'numeric',
          minute: 'numeric',
          month: 'long',
          day: 'numeric',
        });

      retailInfo.timeWindowTooltip = `${localize('general.order.info')} ${tooltipData}`;
    }
  }

  return retailInfo;
};

export default getRetailInfo;
