import { getDataFromResponse } from "src/utils/api/getDataFromResponse";
import { fetcher } from "./fetch";
import { Category, CategoryProductsResponse } from "./types";

export const getCategories = async (
  language: string,
): Promise<Category[]> => {
  const url = '/uber_catalog/categories/';

  const res = await fetcher<Category[]>(url, {
    language,
  });

  return getDataFromResponse(res, []);
};

export const getCategory = async (
  language: string,
  categoryId: string,
) => {
  const url = `/uber_catalog/categories/${categoryId}`;

  const res = await fetcher<Category>(url, { language });
  return res;
};

interface Params {
  language: string;
  searchString: string;
}

export const fetchCategoryData = async (params: Params) => {
  const {
    language,
    searchString,
  } = params;

  const url = `/uber_catalog/products/${searchString}`;
  const res = await fetcher<CategoryProductsResponse>(url, { language });

  return res;
};

