import { tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .FooterSocials__item {
      margin-bottom: 32px;
    }

    @media ${tablet} {
      .FooterSocials__item {
        margin-bottom: 24px;
      }
    }
  `;
};

export default getStyles;
