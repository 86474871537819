import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import useAuth from 'src/services/auth/useAuth';
import useLang from 'src/services/language/useLang';
import { useLocalize } from 'src/services/localize/useLocalize';
import useThemeOld from 'src/services/theme/useThemeOld';
import useUserProfile from 'src/services/userProfile/useUserProfile';
import getStyles from './AccountNavigation.styles';
import AccountNavigationItem from './AccountNavigationItem';
import getAccountNavigationItems from './items';

interface Props {
  place: 'dropdown' | 'account';
}

const AccountNavigation = (props: Props) => {
  const { place } = props;
  const theme = useThemeOld();
  const styles = getStyles(theme);
  const localize = useLocalize();
  const lang = useLang();
  const { pathname } = useRouter();
  const { logout } = useAuth();
  const { userProfile } = useUserProfile();

  const isHorecaUser = userProfile?.is_horeca;
  const userName = userProfile?.name || '';
  const items = getAccountNavigationItems(isHorecaUser);
  const withGreeting = place === 'dropdown';

  return (
    <Fragment>
      <div className={`AccountNavigation AccountNavigation_${place}`}>
        {userName && withGreeting && (
          <Fragment>
            <div className='AccountNavigation__user' data-marker="User name">
              <span>{localize('user.hello')}</span>
              <span className='AccountNavigation__userName' data-testid='navigationUserName'>
                {`, ${userName}`}
              </span>
            </div>
            <div className="AccountNavigation__line" />
          </Fragment>
        )}

        <div className="AccountNavigation__list">
          {items.map(item => {
            const pathParts = pathname.split('/');
            const isActive = pathParts[pathParts.length - 1] === item.name;

            return (
              <div className="AccountNavigation__listItem" key={item.name}>
                <Link href={`/[lang]/${item.url}`} as={`/${lang}/${item.url}/`} legacyBehavior>
                  <a
                    className={cn('AccountNavigation__link', {
                      'AccountNavigation__link_active': isActive,
                    })}
                    data-marker={item.dataMarker}
                  >
                    <AccountNavigationItem
                      iconName={item.icon}
                      text={localize(item.translation_key)}
                      isActive={isActive}
                    />
                  </a>
                </Link>
              </div>
            );
          })}
        </div>
        {withGreeting && (
          <div className="AccountNavigation__line" />
        )}
        <div className="AccountNavigation__listItem AccountNavigation__logout">
          <button
            type='button'
            onClick={logout}
            className='AccountNavigation__link'
            data-testid='logout-button'
            data-marker='Sign out'
          >
            <AccountNavigationItem
              iconName='exit'
              text={localize('nav.exit')}
            />
          </button>
        </div>
      </div>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default AccountNavigation;
