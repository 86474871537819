import { useEffect, useState } from 'react';
import useLang from '../../services/language/useLang';
import useLocalize from '../../services/localize/useLocalize';
import preloadImages from '../../utils/images/preloadImages';
import IconClose from './IconClose';
import styles from './Messengers.styles';
import { SocialMessenger } from './Messengers.types';
import ZendeskWidget from './ZendeskWidget';
import requestGetSocialMessengers from './requestGetSocialMessengers';

interface MessengersProps {
  zendeskKey: string | undefined;
}

const Messengers = (props: MessengersProps) => {
  const { zendeskKey } = props;
  const language = useLang();
  const localize = useLocalize();
  const [socials, setSocials] = useState<SocialMessenger[]>([]);
  const [socialsAreReady, setSocialsAreReady] = useState(false);
  const [listOfMessengersIsOpen, setListOfMessengersIsOpen] = useState(false);
  const [zendeskIsOpen, setZendeskIsOpen] = useState(false);
  const launcherButtonIsDisplay = !zendeskIsOpen;

  // getting social messengers
  useEffect(() => {
    (async () => {
      const response = await requestGetSocialMessengers(language);

      if (response.status === 'success') {
        await preloadImages(response.data.map(m => m.img_url));
        setSocials(response.data);
      }

      setSocialsAreReady(true);
    })();
  }, [language]);

  // enable/disable document click handler to close messenger list
  useEffect(() => {
    if (listOfMessengersIsOpen) {
      const handleClickDocument = (event: MouseEvent) => {
        const target = (event.target as Element);
        const targetIsChildOfMessengers = !!target?.closest(
          '.Messengers');

        if (!targetIsChildOfMessengers) {
          setListOfMessengersIsOpen(false);
        }
      };

      document.addEventListener('click', handleClickDocument, true);

      return () => document.removeEventListener('click', handleClickDocument, true);
    }
  }, [listOfMessengersIsOpen]);

  const handleClickLauncherButton = () => {
    setListOfMessengersIsOpen(true);
  };

  const handleClickCloseButton = () => {
    setListOfMessengersIsOpen(false);
  };

  const handleClickZendeskButton = () => {
    setListOfMessengersIsOpen(false);
    setZendeskIsOpen(true);
  };

  const handleCloseZendeskChat = () => {
    setZendeskIsOpen(false);
  };

  const renderLauncherButton = () => {
    if (!launcherButtonIsDisplay) {
      return null;
    }

    return (
      <div
        className='Messengers__launcher'
        onClick={handleClickLauncherButton}
        title={localize('chat_online')}
        data-testid='launcher_button'
        data-marker='Messengers Launcher'
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img className='Messengers__icon'
             src={'https://img4.zakaz.ua/about_contacts/zendesk3.png'}
             alt={localize('chat_online')} />
        <style jsx>{styles}</style>
      </div>
    );
  };

  const renderList = () => (
    <div
      className='Messengers__list'
      data-testid='messengers_list'
    >
      {
        socialsAreReady && socials.map(socialMessenger => (
          <a key={socialMessenger.name}
             className='Messengers__button'
             target='_blank'
             rel='noreferrer'
             href={socialMessenger.link}
             title={socialMessenger.name}
             onClick={() => setListOfMessengersIsOpen(false)}
             data-marker={`${socialMessenger.name} Button`}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className='Messengers__icon'
                 src={socialMessenger.img_url}
                 alt={socialMessenger.name}
            />
          </a>
        ))
      }

      {
        zendeskKey && (
          <div
            className='Messengers__button'
            onClick={handleClickZendeskButton}
            title={localize('chat_online')}
            data-testid='zendesk_button'
            data-marker='Zendesk Button'
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className='Messengers__icon'
                 src={'https://img4.zakaz.ua/about_contacts/zendesk3.png'}
                 alt={localize('chat_online')} />
          </div>
        )
      }

      <div
        className='Messengers__button'
        onClick={handleClickCloseButton}
        data-testid='close_button'
        data-marker='Close Messengers'
      >
        <IconClose />
      </div>
      <style jsx>{styles}</style>
    </div>
  );

  return (
    <div className='Messengers'>
      {
        listOfMessengersIsOpen ? renderList() : renderLauncherButton()
      }
      {
        zendeskKey && (
          <ZendeskWidget
            zendeskKey={zendeskKey}
            language={language}
            chatIsOpen={zendeskIsOpen}
            onCloseChat={handleCloseZendeskChat}
          />
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default Messengers;
