import { createContext } from 'react';
import { UserGeolocationApi } from 'src/modules/address-management/hooks/useUserGeolocation/useUserGeolocation';
import useDeliveryServices from 'src/modules/delivery/hooks/useDeliveryServices/useDeliveryServices';
import { UserDeliveryService } from '../../../../services/userDelivery/useUserDeliveryService';

export interface AddressManagementPreparerContext {
  addressManagementIsReady: boolean;
  deliveryServices: ReturnType<typeof useDeliveryServices>['deliveryServices'];
  userDelivery: UserDeliveryService['userDelivery'];
  updateUserDelivery: UserDeliveryService['updateUserDelivery'];
  geoCoords: UserGeolocationApi['geoCoords'];
  geoIsAvailable: boolean;
  updateGeoCoords: UserGeolocationApi['updateGeoCoords'];
  amBarProps: {
    skeletonIsDisplayed: boolean;
    deliveryTypeText: string;
    deliveryAddressText: string | null;
  };
}

const addressManagementPreparerContext = createContext<AddressManagementPreparerContext | null>(null);

export default addressManagementPreparerContext;
