import Link from 'next/link';
import React, { AnchorHTMLAttributes, ReactNode, useMemo } from 'react';
import { StylishBoxStyles } from '../StylishBox/types';
import sbsToClassName from '../StylishBox/helpers/sbsToClassName';
import { cx } from '@emotion/css';
import { useTheme } from '../theme';
import getClassNameStyles from './getClassNameStyles';

export type StylishLinkVariant = 'accented' | 'notAccented';

interface StylishLinkProps {
  children: ReactNode;
  href: string;
  /** @default notAccented */
  variant?: StylishLinkVariant;
  /** @default false */
  innerLink?: boolean;
  className?: string;
  sbs?: StylishBoxStyles;
  dataMarker?: string;
  /** @default false */
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel'];
  onClick?(e: React.MouseEvent): void;
}

const StylishLink = (props: StylishLinkProps) => {
  const {
    children,
    href,
    sbs,
    className: classNameProp,
    variant = 'notAccented',
    target,
    innerLink = false,
    rel,
    dataMarker,
    onClick,
  } = props;
  const theme = useTheme();
  const classNameSbs = sbsToClassName(sbs);
  const classNameStyles = getClassNameStyles(variant, theme);

  const Component = useMemo(() => innerLink ? 'a' : Link, [innerLink]);

  return (
    <Component
      href={href}
      className={cx(classNameStyles, classNameSbs, classNameProp)}
      target={target}
      rel={rel}
      data-marker={dataMarker}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};

export default StylishLink;
