import { ThemeOld } from 'src/styles/theme-old';
import css from 'styled-jsx/css';
import typography from '../../styles/typography';

const getStyles = (theme: ThemeOld) => {
  return css`
    .NotificationBar {
      background-color: ${theme.colors.barleyWhite};
      ${typography.text};
      line-height: 24px;
      padding: 12px 16px;
      margin: 0;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .NotificationBar :global(strong) {
      font-weight: 600;
    }
  `;
};

export default getStyles;
