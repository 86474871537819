import css from 'styled-jsx/css';
import { phone } from "../../../../styles/media";
import themeOld from "../../../../styles/theme-old";
import typography from "../../../../styles/typography";

const getStyles = () => {
  return css`
    .ForgotLoginModal {
      width: 410px;
      padding: 40px;
      background-color: ${themeOld.colors.white};
      border-radius: ${themeOld.commonParams.borderRadius};
    }
    .ForgotLoginModal__title {
      text-align: center;
      ${typography.h3}
      margin-bottom: 16px;
    }
    .ForgotLoginModal__description {
      text-align: center;
      ${typography.text}
      margin-bottom: 24px;
    }
    @media ${phone} {
      .ForgotLoginModal {
        width: 100vw;
        padding: 35px 10px;
        border-radius: 0;
      }
    }
  `;
};

export default getStyles;
