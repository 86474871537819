import { colors, fonts } from './theme-old';

export const typographyJSStyles = {
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '36px',
    * * lineHeight: '40px',
    * * color: '#303030',
   */
  h1: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '40px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '24px',
    * * lineHeight: '28px',
    * * color: '#303030',
   */
  h2: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '18px',
    * * lineHeight: '24px',
    * * color: '#303030',
   */
  h3: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '16px',
    * * lineHeight: '22px',
    * * color: '#303030',
   */
  h4: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '16px',
    * * lineHeight: '21px',
    * * color: '#303030',
   */
  h5: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color: '#303030',
   */
  h6: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color: '#303030',
   */
  h9: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color:' #303030',
   */
  text: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color:' #303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color:' #303030',
   */
  smallAccentedText: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color:' #303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color: '#9F9F9F',
   */
  notAccentedText: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#9F9F9F',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color: '#9F9F9F',
   */
  notAccentedH9: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#9F9F9F',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '14px',
   */
  btn: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '28px',
    * * lineHeight: '34px',
    * * color: '#303030',
   */

  h1Mobile: {
    fontFamily: fonts.default,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '34px',
    color: '#303030',
  },
  /**
    * * fontFamily: fonts.default,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '16px',
    * * color: '#ff5252',
   */
  error: {
    fontFamily: fonts.default,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.red,
  },
};

function transformProps(prop) {
  let result = prop;
  for (var i = 0; i < prop.length; i++) {
    if (prop[i] === prop[i].toUpperCase()) {
      result = `${result.substring(0, i)}-${prop[i].toLowerCase()}${result.substring(i+1)}`;
    }
  }
  return result;
}

function createCSSStyles(obj) {
  let result = {};
  for (let key in obj) {
    result[key] = '';
    for (let prop in obj[key]) {
      result[key] += `${transformProps(prop)}:${obj[key][prop]};`;
    }
  }
  return result;
}

const typography = createCSSStyles(typographyJSStyles) as typeof typographyJSStyles;

export default typography;
