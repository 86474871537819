import { ReactNode } from 'react';
import { getStyles } from './ErrorMessage.styles';

interface Props {
  children: ReactNode;
  dataTestId?: string;
}

const ErrorMessage = (props: Props) => {
  const { children, dataTestId } = props;
  const styles = getStyles();
  return (
    <p
      className='ErrorMessage'
      data-testid={dataTestId}
    >
      {children}
      <style jsx>{styles}</style>
    </p>
  );
};

export default ErrorMessage;
