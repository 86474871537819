import { ThemeOld } from 'src/styles/theme-old';
import css from 'styled-jsx/css';

const getStyles = (theme: ThemeOld) => {
  return css`
    .RetailInfoCard {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      padding: 16px 12px;
      background-color: #fff;
      border-radius: ${theme.commonParams.borderRadius};
      font-size: 14px;
      line-height: 22px;
      text-decoration: none;
      transition: all 0.4s;
      overflow: hidden;
    }

    .RetailInfoCard:hover {
      box-shadow: 0 8px 16px rgba(34, 36, 33, 0.12);
      opacity: 1;
    }

    .RetailInfoCard_unavailable {
      align-items: center;
      justify-content: center;
      opacity: 0.7;
    }

    .RetailInfoCard__logo {
      display: flex;
      flex-shrink: 1;
      height: 36px;
      margin: auto 0;
      align-items: center;
      justify-content: center;
    }

    .RetailInfoCard__timeWindow {
      margin: 12px 0 auto;
    }

    .RetailInfoCard__promo {
      margin: 12px 0 auto;
    }

    .RetailInfoCard__timeWindow + .RetailInfoCard__promo {
      margin-top: 2px;
    }

    .RetailInfoCard__cart {
      position: absolute;
      top: 17px;
      right: 24px;
    }

    /* size medium */
    .RetailInfoCard_medium .RetailInfoCard__timeWindow {
      margin: 8px 0 auto;
    }

    .RetailInfoCard_medium .RetailInfoCard__promo {
      margin: 8px 0 auto;
    }

    /* size small */
    .RetailInfoCard_small {
      padding: 12px;
    }

    .RetailInfoCard_small .RetailInfoCard__timeWindow {
      margin: 8px 0 auto;
    }

    .RetailInfoCard_small .RetailInfoCard__promo {
      margin: 8px 0 auto;
    }
  `;
};

export default getStyles;
