import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Delivery } from '../../modules/delivery/types';
import getDeliveryFromServerCart from './helpers/getDeliveryFromServerCart';


export const USER_DELIVERY_KEY = 'userDelivery';

export interface UserDeliveryService {
  userDelivery: Delivery | null;
  userDeliveryIsReady: boolean;
  updateUserDelivery(delivery: Delivery | null): void;
}

const useUserDeliveryService = (language: string): UserDeliveryService => {
  const [userDelivery, setUserDelivery] = useLocalStorage<Delivery | null>(USER_DELIVERY_KEY, null);
  const [userDeliveryIsReady, setUserDeliveryIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (userDelivery === null) {
        const newUserDelivery = await getDeliveryFromServerCart(language);

        setUserDelivery(newUserDelivery);
      }

      setUserDeliveryIsReady(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserDelivery = (delivery: Delivery | null) => setUserDelivery(delivery);

  return {
    userDelivery,
    userDeliveryIsReady,
    updateUserDelivery,
  };
};

export default useUserDeliveryService;
