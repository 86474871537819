import React, { createElement, Fragment, ReactNode } from 'react';
import cn from 'classnames';
import sbsToClassName from './helpers/sbsToClassName';
import { StylishBoxStyles } from './types';

export type StylishBoxAllowedTagName = keyof React.ReactHTML;

type DataAttribute = {
  [dataAttribute: `data-${string}`]: any;
  /** Please use dataMarker of StylishBoxProps*/
  'data-marker'?: undefined;
  /** Please use dataTestId of StylishBoxProps*/
  'data-testid'?: undefined;
};

export type StylishBoxElementProps<T extends StylishBoxAllowedTagName> =
  Omit<JSX.IntrinsicElements[T], 'children' | 'className'>
  & DataAttribute

export type StylishBoxCommonProps = {

}

export type StylishBoxProps<T extends StylishBoxAllowedTagName> = {
  children?: ReactNode;
  element?: T;
  sbs?: StylishBoxStyles;
  elementProps?: StylishBoxElementProps<T>;
  className?: string;
  dataMarker?: string;
  dataTestId?: string;
};

const StylishBox = <T extends StylishBoxAllowedTagName>({
  children,
  sbs,
  element = 'div' as T,
  className: classNameProps,
  elementProps,
  dataMarker,
  dataTestId,
}: StylishBoxProps<T>) => {
  const className = sbsToClassName(sbs);
  const totalClassName = cn(className, classNameProps);

  const component = createElement(
    element,
    {
      ...elementProps,
      className: totalClassName,
      'data-marker': dataMarker,
      'data-testid': dataTestId,
    },
    children,
  );

  return (
    <Fragment>
      { component }
    </Fragment>
  );
};

export default StylishBox;
