import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .RetailsInfoSkeleton_large .RetailsInfoSkeleton__item {
      height: 148px;
    }

    .RetailsInfoSkeleton_medium .RetailsInfoSkeleton__item {
      height: 140px;
    }
    
    .RetailsInfoSkeleton_small .RetailsInfoSkeleton__item {
      height: 76px;
    }
  `;
};

export default getStyles;
