import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const getStyles = () => {
  return css`
    .CountdownMessage {
      ${typography.text}
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .CountdownMessage__clock {
      ${typography.h6}
    }
  `;
};
