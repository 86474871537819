import InfoTooltip from "src/components/Tooltip/Tooltip";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { Typography } from "src/ui-kit/Typography";
import { useTheme } from "src/ui-kit/theme";

export interface RetailTimeWindowProps {
  timeWindowText: string;
  timeWindowTooltip?: string | null;
  dataMarkerValue?: string;
  id: string;
  textSize?: 'text2' | 'text6';
}

const RetailTimeWindow = (props: RetailTimeWindowProps) => {
  const { timeWindowText, timeWindowTooltip, dataMarkerValue, id, textSize = 'text6' } = props;

  const theme = useTheme();

   return (
    <StylishBox
      sbs={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant={textSize}
        dataMarker={ dataMarkerValue }
        dataTestId={`typo_${textSize}`}
      >
        { timeWindowText }
      </Typography>

      {timeWindowTooltip && (
        <StylishBox
          dataTestId="tooltip"
          sbs={{
            ml: '7px',
            color: theme.base.color.gray600,
            display: {
              tabletPhone: 'none',
            },
          }}
        >
          <InfoTooltip
            id={id}
            content={timeWindowTooltip}
          />
        </StylishBox>
      )}
    </StylishBox>
   );
};

export default RetailTimeWindow;
