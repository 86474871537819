import { Fragment } from 'react';
import { COUNTRY } from '../../data/constants';
import SocialItem from './SocialItem';
import styles from './Socials.styles';
import { SocialsMap } from './types';


const socials: SocialsMap = {
  ua: [
    {
      type: "facebook",
      url: 'https://www.facebook.com/Zakaz.ua',
    },
    {
      type: "instagram",
      url: 'https://www.instagram.com/zakaz.ua/',
    },
    {
      type: "linkedin",
      url: 'https://www.linkedin.com/company/zakaz-ua/',
    },
    {
      type: "tiktok",
      url: 'https://www.tiktok.com/@zakaz.ua',
    },
  ],
};

interface Props {
  dataMarker?: String;
}

const Socials = (props: Props) => {
  const { dataMarker } = props;

  return (
    <Fragment>
      <div className="Socials" data-marker={dataMarker}>
        {socials[COUNTRY].map((social, index) => (
          <div className="Socials__item" key={index}>
            <SocialItem {...social} />
          </div>
        ))}
        <style jsx>{styles}</style>
      </div>
    </Fragment>
  );
};

export default Socials;
