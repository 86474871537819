import { FetcherResponse } from "src/api/fetch";
import { ApiErrorShape } from "src/api/types";

interface HandleResponseWithRetryParams<T> {
  onError: (errors: ApiErrorShape[]) => void;
  onRetry: (retryAfter: number) => void;
  onSuccess: () => void;
  response: FetcherResponse<T>;
}

export const handleResponseWithRetry = <T>({
  onError,
  onRetry,
  onSuccess,
  response,
}: HandleResponseWithRetryParams<T>) => {
  const errors = response.status === 'fail' ? response.errors : [];

  if (errors.length && errors[0].error_code !== 4029) {
    onError(errors);
  } else {
    onSuccess();
  }

  if (response.retryAfter) {
    onRetry(Number(response.retryAfter));
  }
};
