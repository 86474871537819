import { COUNTRY } from "src/data/constants";
import useLocalize from "src/services/localize/useLocalize";
import IconFont from "src/ui-kit/IconFont";
import { Typography } from "src/ui-kit/Typography";
import { useTheme } from "src/ui-kit/theme";

const ZsuButton = () => {
  const localize = useLocalize();
  const theme = useTheme();

  if (COUNTRY === 'ua') {
    return (
      <a
        rel='noreferrer nofollow'
        href='https://bank.gov.ua/ua/about/support-the-armed-forces'
        target="_blank"
        data-marker="zsu-help"
      >
        <Typography
          element="span"
          variant='text6'
          sbs={{
            cursor: 'pointer',
            hover: {
              color: theme.base.color.primary,
            },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {localize('zsu_btn')}
          <IconFont
            name='arrow-up-right'
            size='12px'
            sbs={{
              ml: '4px',
            }}
          />
        </Typography>
      </a>
    );
  }

  return null;
};

export default ZsuButton;
