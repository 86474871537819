import { useMediaQuery } from 'react-responsive';
import GeneralDrawerModal from 'src/components/GeneralDrawerModal/GeneralDrawerModal';
import GeneralDrawerModalLayout from 'src/components/GeneralDrawerModal/GeneralDrawerModalLayout';
import AddressManagementBar from 'src/modules/address-management/components/AddressManagementBar/AddressManagementBar';
import { sendDeliveryAddressPopupShow } from 'src/modules/address-management/helpers/AddressManagementAnalytics';
import useModals from 'src/modules/modals/useModals';
import RetailsInfo from 'src/modules/retails/components/RetailsInfo/RetailsInfo';
import { sendChainCardClick } from 'src/modules/retails/helpers/retailsInfoAnalytics';
import { useLocalize } from 'src/services/localize/useLocalize';
import { phone } from 'src/styles/media';
import getStyles from './SidebarMenu.styles';

const SidebarMenu = () => {
  const styles = getStyles();
  const modals = useModals();
  const localize = useLocalize();
  const isPhone = useMediaQuery({ query: phone });
  const componentsSize = isPhone ? 'small' : 'medium';
  const place = 'drawer';

  const handleAmButtonClick = () => {
    modals.openModal('addressManagement');
    sendDeliveryAddressPopupShow(place);
  };

  const handleRetailInfoCardClick = (isAvailable: boolean) => {
    sendChainCardClick(place, isAvailable);
  };

  const handleCloseModal = () => modals.closeModal('retailsDrawerModal');

  return (
    <div className="SidebarMenu">
      <button
        className="SidebarMenu__button"
        onClick={() => modals.openModal('retailsDrawerModal')}
        data-marker="sidebar-button"
      />
      <GeneralDrawerModal
        onClose={handleCloseModal}
        isOpen={modals.state.retailsDrawerModal.isOpen}
      >
        <GeneralDrawerModalLayout
          title={localize('all_stores')}
          onClose={handleCloseModal}
        >
          <div className="SidebarMenu__content">

            <div className="SidebarMenu__address">
              <AddressManagementBar
                size={componentsSize}
                onAmButtonClick={handleAmButtonClick}
              />

              <RetailsInfo
                size={componentsSize}
                onClickOpenAm={handleAmButtonClick}
                onRetailInfoCardClick={handleRetailInfoCardClick}
              />
            </div>
          </div>
        </GeneralDrawerModalLayout>
      </GeneralDrawerModal>
      <style jsx>{styles}</style>
    </div>
  );
};

export default SidebarMenu;
