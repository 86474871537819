import { IconFontName } from '../../ui-kit/IconFont';

interface AccountNavigationItem {
  name: string;
  translation_key: string;
  url: string;
  icon: IconFontName;
  dataMarker: string;
}

const getAccountNavigationItems = (isUserHoreca = false): AccountNavigationItem[] => {
  return [
    {
      name: 'orders',
      translation_key: 'nav.orders',
      url: 'account/orders',
      icon: 'calendar',
      dataMarker: 'Orders',
    },
    {
      name: 'addresses',
      translation_key: 'nav.addresses',
      url: 'account/addresses',
      icon: 'pin',
      dataMarker: 'Addresses',
    },
    {
      name: 'newsletter',
      translation_key: 'nav.newsletter',
      url: 'account/newsletter',
      icon: 'letter',
      dataMarker: 'Newsletter',
    },
    {
      name: 'settings',
      translation_key: 'nav.settings',
      url: 'account/settings',
      icon: 'settings',
      dataMarker: 'Settings',
    },
    {
      name: 'horeca',
      translation_key: isUserHoreca ? 'profile_b2b_client' : 'company_registration',
      url: 'account/horeca',
      icon: 'briefcase',
      dataMarker: 'HorecaSettings',
    },
  ];
};

export default getAccountNavigationItems;
