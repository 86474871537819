import { useLocalize } from '../../services/localize/useLocalize';
import useThemeOld from '../../services/theme/useThemeOld';
import { Typography } from '../../ui-kit/Typography';
import getStyles from './ContactInformation.styles';

const ContactInformation = () => {
  const theme = useThemeOld();
  const localize = useLocalize();
  const styles = getStyles(theme);

  return (
    <div className="ContactInformation">
      <Typography variant='text3' sbs={{ mb: '12px' }}>
        {localize('pages_contacts')}
      </Typography>
      <div className="ContactInformation__item">
        <a className="ContactInformation__itemAction"
          href={`tel:${localize('contact_center.phone')}`}>
          {localize('contact_center.phone')}
        </a>
      </div>
      <div className="ContactInformation__item">
        <a className="ContactInformation__itemAction"
          href={`tel:${localize('contact_center.info_email')}`}>
          {localize('contact_center.info_email')}
        </a>
      </div>
      <Typography dataMarker='Footer Address'>
        {localize('company_adress')}
      </Typography>

      <style jsx>{styles}</style>
    </div>
  );
};

export default ContactInformation;
