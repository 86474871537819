import React from 'react';
import getStyles from './GeneralModalCloseButton.styles';

export interface Props {
  onClick(): void;
}

export default function GeneralModalCloseButton(props: Props) {
  const { onClick } = props;

  const styles = getStyles();

  return (
    <div
      className='GeneralModalCloseButton'
      role='button'
      onClick={onClick}
      data-marker='Close popup'
      aria-label='Close popup'
    >
      <div className='GeneralModalCloseButton__iconContainer'>
        <span className='GeneralModalCloseButton__icon' />
      </div>

      <style jsx>{styles}</style>
    </div>
  );
}
