import classNames from 'classnames';
import { KeyboardEvent, ReactNode } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { COUNTRY } from '../../data/constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { getContainerResolve, getInputResolve, styles } from './PhoneInput.styles';

export type PhoneInputChangeHandler = (value: string, formatted: string) => void;

interface Props {
  autoFocus?: boolean;
  inputName: string;
  label?: string;
  onChange: PhoneInputChangeHandler;
  value: string;
  dataMarker?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorText?: ReactNode;
  onKeyDown?(event: KeyboardEvent<HTMLInputElement>): void;
}

const PhoneInput = (props: Props) => {
  const {
    inputName,
    label,
    onChange,
    onKeyDown,
    value,
    dataMarker,
    autoFocus,
    disabled,
    errorText,
    hasError,
  } = props;

  const { className: inputResolveClassName, styles: inputStyles } = getInputResolve();
  const { className: containerResolveClassName, styles: containerStyles } = getContainerResolve();

  const inputProps = {
    autoFocus,
    name: inputName,
    id: inputName,
    'data-marker': dataMarker || undefined,
  };

  return (
    <div className='PhoneInput'>
      {
        label && (
          <label
            htmlFor={inputName}
            className='PhoneInput__label'
          >
            {label}
          </label>
        )
      }
      <ReactPhoneInput
        inputClass={inputResolveClassName}
        containerClass={classNames(containerResolveClassName, { 'react-tel-input_disabled': disabled })}
        value={value}
        country={COUNTRY}
        onChange={(v, d, e, f) => onChange(v, f)}
        inputProps={inputProps}
        disabled={disabled}
        isValid={!hasError}
        disableDropdown
        countryCodeEditable={false}
        onKeyDown={onKeyDown}
        copyNumbersOnly={false}
      />
      {errorText && (
        <div className="PhoneInput__error">
          <ErrorMessage dataTestId='phone-error'>
            {errorText}
          </ErrorMessage>
        </div>
      )}

      <style jsx>{styles}</style>
      {inputStyles}
      {containerStyles}
    </div>
  );
};

export default PhoneInput;
