import { getNotifications } from 'src/modules/retails/helpers/getNotifications';
import { getSingleRetailChain } from 'src/modules/retails/helpers/getSingleRetailChain';
import { PathHandler } from '../getRedirectPath';

export function isNotificationPath(path: string) {
  const notificationsRegex = /^\/[a-zA-Z]{2}\/[nN]\/[^/]+\/?$/;
  const isMatchPath = notificationsRegex.test(path);

  return isMatchPath;
}

const handleNotifications: PathHandler = async ({ path }) => {
  if (isNotificationPath(path)) {
    const [, language, , id] = path.split('/');

    const chain = await getNotifications(id);

    if (!chain) {
      return { path, isNotFound: true };
    }

    const chainInfo = await getSingleRetailChain(chain);

    if (!chainInfo || !chainInfo.hostname) {
      return { path, isNotFound: true };
    }

    const newPath = `https://${chainInfo.hostname}/${language}/n/${id}/`;

    return { path: newPath, stopModifying: true };
  }

  return { path };
};

export default handleNotifications;
