import { PathHandler } from '../getRedirectPath';
import getSegmentInfo from '../helpers/getSegmentInfo';

export const HELP_ROUTES = ['how-it-works', 'delivery'];

const handleHelpPages: PathHandler = ({ path }) => {
  const { segments, langSegment } = getSegmentInfo(path);

  const isHelpRoute = segments.some((s) => HELP_ROUTES.includes(s));

  if (isHelpRoute) {
    const newPath = `/${langSegment}/help/`;

    return { path: newPath };
  }

  return { path };
};

export default handleHelpPages;
