import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const LANG_LIST: string = publicRuntimeConfig.NEXT_APP_LANG_LIST || '';
export const LANGUAGES: string[] = LANG_LIST.split(',');
export type Country = 'ua';
export const DEFAULT_LANG = publicRuntimeConfig.NEXT_APP_DEFAULT_LANG;
export const CURRENCY = publicRuntimeConfig.NEXT_APP_CURRENCY;
export const NEW_YEAR_AVAILABLE = publicRuntimeConfig.NEXT_APP_NEW_YEAR_AVAILABLE;
export const COUNTRY = publicRuntimeConfig.NEXT_APP_COUNTRY as Country;
export const LOGO_URL = `https://img4.zakaz.ua/store_logos/zakaz_${COUNTRY}.svg`;
export const BASE_URL = publicRuntimeConfig.NEXT_APP_BASE_URL;
export const GOOGLE_API_KEY = publicRuntimeConfig.NEXT_APP_GOOGLE_API_KEY;
export const SENTRY_DSN = publicRuntimeConfig.NEXT_APP_SENTRY_DSN;
export const ENVIRONMENT_NAME = publicRuntimeConfig.NEXT_APP_ENVIRONMENT_NAME;
export const SKIN = publicRuntimeConfig.NEXT_APP_SKIN;
export const GOOGLE_SITE_VERIFICATION = publicRuntimeConfig.NEXT_APP_GOOGLE_SITE_VERIFICATION;
export const EXTRA_CHAINS_URL = publicRuntimeConfig.NEXT_APP_EXTRA_CHAINS_URL as string;
export const POSTOMAT_IS_AVAILABLE = publicRuntimeConfig.NEXT_APP_POSTOMAT_IS_AVAILABLE;

export const PACKAGE_NAME = `${publicRuntimeConfig.PACKAGE_NAME}`;
export const PACKAGE_VERSION = `${publicRuntimeConfig.PACKAGE_VERSION}`;

export const SITE_TITLE = publicRuntimeConfig.SITE_TITLE;

export const IS_SERVER = typeof window === 'undefined';

export const apiRoot = publicRuntimeConfig.NEXT_APP_API_URI;

export const GTM_ID = publicRuntimeConfig.NEXT_APP_GTM_ID;
export const CROSS_DOMAIN_GTM_ID = publicRuntimeConfig.NEXT_APP_CROSS_DOMAIN_GTM_ID;

export const AD_BANNER_ENABLED = true;
export const MIN_SEARCH_LENGTH = 3;
export const LATEST_API_VERSION = '60';
export const DEFAULT_PRODUCTS_PER_PAGE = 28;
export const DEFAULT_PRODUCTS_FOR_SLIDER_COUNT = 28;

export const ZENDESK_KEY = publicRuntimeConfig.NEXT_APP_ZENDESK_KEY;
export const WITH_UBERCATALOG = publicRuntimeConfig.NEXT_APP_WITH_UBERCATALOG;

export const RECAPTCHA_PUBLIC_KEY = publicRuntimeConfig.NEXT_APP_RECAPTCHA_KEY;
export const COUNTRY_CODE = publicRuntimeConfig.NEXT_APP_COUNTRY_CODE || '+380';

export const MAP_MIN_ZOOM = 2;
export const MAP_MAX_ZOOM = 18;

export const IS_ANDROID =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.userAgent &&
  window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
export const IS_IOS =
  typeof window !== 'undefined' &&
  window.navigator &&
  !!window.navigator.userAgent &&
  /iPad|iPhone|iPod/.test(window.navigator.userAgent);

export const IOS_APP_ID = publicRuntimeConfig.NEXT_APP_IOS_APP_ID;
export const ANDROID_APP_ID = publicRuntimeConfig.NEXT_APP_ANDROID_APP_ID;
export const MOBILE_PLATFORM = IS_ANDROID
  ? 'android'
  : IS_IOS
    ? 'ios'
    : null;

// COUNTRIES
export const IS_UKRAINE = COUNTRY === "ua";
export const metaXChain = '*';

export const HORECA_B2B_LINK = publicRuntimeConfig.NEXT_APP_HORECA_B2B_LINK;
export const FILTER_PRICE_SEPARATOR = '-';
