import { fetcher } from 'src/api/fetch';
import { Delivery } from 'src/modules/delivery/types';
import { CoordsNumbers, NovaPoshtaStreet, Settlement, Warehouse } from '../types';

/**
 * @description
  This type has partial structure of real server cart delivery types,
  and is used only in getDeliveryFromServerCart helper.
  Please don't use this type anywhere else.
*/
export type ServerCartDeliveryPartial =
  ServerCartDeliveryPlanPartial |
  ServerCartDeliveryPickupPartial |
  ServerCartDeliveryNovaPoshtaPartial |
  ServerCartDeliveryNovaPoshtaFreshPartial |
  ServerCartDeliveryNovaPoshtaAddressPartial;

interface ServerCartDeliveryPlanPartial {
  type: 'plan';
  address: {
    plan: {
      city: string;
      street: string;
      building: string;
      coords: CoordsNumbers;
      delivery_service_id: string;
    };
  };
}

interface ServerCartDeliveryPickupPartial {
  type: 'pickup';
  address: {
    pickup: {
      store: {
        delivery_service: string;
      };
    };
  };
}

interface ServerCartDeliveryNovaPoshtaPartial {
  type: 'nova_poshta';
  address: {
    nova_poshta: {
      settlement: Settlement;
      warehouse: Warehouse;
    };
  };
}

interface ServerCartDeliveryNovaPoshtaFreshPartial {
  type: 'nova_poshta_fresh';
  address: {
    nova_poshta_fresh: {
      settlement: Settlement;
      warehouse: Warehouse;
    };
  };
}

interface ServerCartDeliveryNovaPoshtaAddressPartial {
  type: 'nova_poshta_address';
  address: {
    nova_poshta_address: {
      address: {
        settlement: Settlement;
        street: NovaPoshtaStreet;
        building: string;
      };
    };
  };
}
/* End of server cart delivery types */

async function requestGetServerCartDelivery(language: string): Promise<ServerCartDeliveryPartial | null> {
  const response = await fetcher<{ delivery: ServerCartDeliveryPartial }>(`/user/delivery`, {
    credentials: 'include',
    language,
  });

  return response.status === 'success' ? response.data.delivery : null;
}

function convertToDelivery(serverCartDelivery: ServerCartDeliveryPartial | null): Delivery | null {
  if (!serverCartDelivery) {
    return null;
  }

  if (serverCartDelivery.type === 'plan') {
    const {
      city,
      street,
      building,
      coords,
      delivery_service_id,
    } = serverCartDelivery.address.plan;

    return {
      type: 'plan',
      geocodedAddress: {
        city,
        street,
        building,
        coords,
      },
      deliveryServiceId: delivery_service_id,
    };
  }

  if (serverCartDelivery.type === 'pickup') {

    return {
      type: 'pickup',
      deliveryServiceId: serverCartDelivery.address.pickup.store.delivery_service,
    };
  }

  if (serverCartDelivery.type === 'nova_poshta_address') {
    const {
      settlement,
      building,
      street,
    } = serverCartDelivery.address.nova_poshta_address.address;

    return {
      type: 'nova_poshta_address',
      settlement,
      building,
      street,
    };
  }

  if (serverCartDelivery.type === 'nova_poshta' || serverCartDelivery.type === 'nova_poshta_fresh') {
    const {
      settlement,
      warehouse,
    } = serverCartDelivery.type === 'nova_poshta' ?
        serverCartDelivery.address.nova_poshta : serverCartDelivery.address.nova_poshta_fresh;

    return {
      type: 'nova_poshta',
      settlement,
      warehouse,
    };
  }

  throw new Error('Error, server cart delivery has unknown type');
}

async function getDeliveryFromServerCart(language: string): Promise<Delivery | null> {
  const serverCartDelivery = await requestGetServerCartDelivery(language);

  return convertToDelivery(serverCartDelivery);
}

export default getDeliveryFromServerCart;
