import { useEffect, useRef } from "react";
import useAuth from "../../services/auth/useAuth";

const AuthSynchronizerWatcher = () => {
  const { isFetching, checkIsLoggedIn } = useAuth();
  const isFetchingRef = useRef<boolean | null>(null);
  isFetchingRef.current = isFetching;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if(!document.hidden && !isFetchingRef.current) {
        checkIsLoggedIn();
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [checkIsLoggedIn]);

  return null;
};

export default AuthSynchronizerWatcher;
