import css from 'styled-jsx/css';
import themeOld from '../../styles/theme-old';
import typography from '../../styles/typography';

export const styles = css`
  .PhoneInput__label {
    display: block;
    ${typography.h9}
    margin-bottom: 2px;
  }
  .PhoneInput__error {
    margin-top: 4px;
  }
`;

export const getInputResolve = () => {
  return css.resolve`
    input[type='tel'] {
      width: 100%;
      border: 1px solid ${themeOld.colors.brandLightGrey2};
      border-radius: 4px;
      display: block;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      font-family: ${themeOld.fonts.default};
      color: ${themeOld.colors.text};
    }
    input[type='tel']:disabled {
      color: ${themeOld.colors.doveGray};
      background: ${themeOld.colors.brandLightGrey5};
    }
    input[type='tel'].invalid-number,
    input[type='tel'].invalid-number:hover,
    input[type='tel'].invalid-number:focus {
      border-color: ${themeOld.colors.red};
      background: ${themeOld.colors.white};
    }

    input[type='tel']:focus:not(.invalid-number) {
      border: 1px solid ${themeOld.colors.doveGray};
    }

    input[type='tel']:focus:not(.invalid-number) + :global(.flag-dropdown) {
      border-color: ${themeOld.colors.doveGray};
    }
  `;
};

export const getContainerResolve = () => {
  return css.resolve`
    div :global(.flag-dropdown) {
      background-color: #fff;
      border: 1px solid ${themeOld.colors.brandLightGrey2};
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    div :global(.flag-dropdown):hover {
      cursor: default;
      background-color: transparent !important;
    }
    div :global(.flag-dropdown.invalid-number) {
      border-color: ${themeOld.colors.red};
    }
    div :global(.flag-dropdown .selected-flag) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    div :global(.flag-dropdown .flag) {
      left: 12px;
    }
    div.react-tel-input_disabled :global(.flag-dropdown),
    div.react-tel-input_disabled :global(.flag-dropdown:hover) {
      background: ${themeOld.colors.brandLightGrey5};
      cursor: not-allowed;
    }
  `;
};
