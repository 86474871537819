import { ChangeEventHandler, useState } from "react";
import IconFont from '../../ui-kit/IconFont';
import Button from 'src/ui-kit/Button';
import FormField from "../FormField/FormField";
import getStyles from "./PasswordInput.styles";

export interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  label: string;
  id?: string;
  dataMarker?: string;
  error?: string;
}

const PasswordInput = (props: Props) => {
  const styles = getStyles();
  const { onChange, value, label, id, dataMarker, error } = props;
  const [isSafe, setIsSafe] = useState(true);
  const type = isSafe ? 'password' : 'text';

  return (
    <div className="PasswordInput">
      <FormField
        id={id || 'password'}
        onChange={onChange}
        value={value}
        label={label}
        inputProps={{
          dataMarker: dataMarker || 'password',
          type,
          style: {paddingRight: '30px'},
          error,
          maxLength: 50,
        }}
      />
      <div className="PasswordInput__button">
        <Button
          variant="ghost"
          onClick={() => setIsSafe(!isSafe)}
          dataMarker="show password"
          tabIndex={-1}
        >
          <IconFont name={isSafe ? 'eye-close' : 'eye'} />
        </Button>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default PasswordInput;
