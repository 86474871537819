import { Fragment } from 'react';
import getStyles from './LoadingRing.styles';

interface Props {
  color?: string;
  size?: string;
  thickness?: string;
}

function LoadingRing(props: Props) {
  const { color = 'white', size = '30', thickness = '4' } = props;
  const styles = getStyles(color, size);

  return (
    <Fragment>
      <div className='LoadingRing' data-testid='LoadingRing'>
        <svg className='LoadingRing__circularLoader' viewBox='25 25 50 50'>
          <circle
            className='LoadingRing__loaderPath'
            cx='50'
            cy='50'
            r='20'
            fill='none'
            stroke={color}
            strokeWidth={thickness}
          />
        </svg>
      </div>

      <style jsx>{styles}</style>
    </Fragment>
  );
}

export default LoadingRing;
