import { PathHandler } from '../getRedirectPath';

const handleCase: PathHandler = ({ path }) => {
  const splitPath = path.split('?');
  const [uri, params] = splitPath;
  const newPath = `${uri.toLowerCase()}${params ? `?${params}` : ''}`;

  return { path: newPath };
};

export default handleCase;
