import IconFont, { IconFontName } from '../../ui-kit/IconFont';
import Stack from '../../ui-kit/Stack/Stack';
import { useTheme } from '../../ui-kit/theme';
import { Typography } from '../../ui-kit/Typography';
import getItemClassName from './getItemClassName';

interface AccountNavigationItemProps {
  iconName: IconFontName;
  text: string;
  isActive?: boolean;
}

const AccountNavigationItem = (props: AccountNavigationItemProps) => {
  const {iconName, text, isActive} = props;
  const theme = useTheme();
  const rootClassName = getItemClassName(theme, isActive);

  return (
    <Stack dataMarker='AccountNavigationItem' alignItems='center' gap='v3' className={rootClassName}>
      <IconFont name={iconName} size='14px' color='gray300' className='AccountNavigationItem__icon'/>
      <Typography variant='text6' className='AccountNavigationItem__text'>{text}</Typography>
    </Stack>
  );
};

export default AccountNavigationItem;
