import { phone } from "src/styles/media";
import { Theme } from "src/ui-kit/theme/types";
import css from "styled-jsx/css";

export const getStyles = (theme: Theme) => {
  return css`
    .Header {
      background: ${theme.base.color.white};
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      height: 64px;
      z-index: ${theme.base.zIndex.header};
      border-bottom: ${theme.base.border.v1} ${theme.base.color.gray200};
    }

    @media ${phone} {
      .Header {
        height: 57px;
      }
    }
  `;
};
