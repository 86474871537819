import { cx } from '@emotion/css';
import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import StylishBox, { StylishBoxProps } from '../StylishBox/StylishBox';
import { StylishBoxStyles } from '../StylishBox/types';
import { useTheme } from '../theme';
import getClassNameStyles from './getClassNameStyles';

type ButtonType = 'button' | 'submit' | 'reset';

export type ButtonVariant =
  | 'primary'
  | 'primarySmall'
  | 'secondary'
  | 'secondarySmall'
  | 'ghost'
  | 'notAccentedGhost';

interface ButtonProps {
  children: ReactNode;
  dataMarker?: string;
  disabled?: boolean;
  tabIndex?: number;
  type?: ButtonType;
  variant: ButtonVariant;
  className?: string;
  width?: StylishBoxStyles['width'];
  sbs?: StylishBoxStyles;
  href?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  onClick?(e: React.MouseEvent): void;
}

function isLink(boxProps: StylishBoxProps<'a' | 'button'>): boxProps is StylishBoxProps<'a'> {
  return boxProps.element === 'a';
}

function isButton(boxProps: StylishBoxProps<'a' | 'button'>): boxProps is StylishBoxProps<'button'> {
  return boxProps.element === 'button';
}

const Button = (props: ButtonProps) => {
  const {
    children,
    href,
    target,
    dataMarker,
    disabled,
    variant,
    width,
    tabIndex,
    type,
    className: classNameProp,
    sbs: sbsProps = {},
    onClick,
  } = props;

  const theme = useTheme();

  const classNameStyles = getClassNameStyles(variant, theme);

  const boxProps: StylishBoxProps<'a' | 'button'> = {
    children,
    element: href ? 'a' : 'button',
    dataMarker: dataMarker,
    elementProps: {
      tabIndex: tabIndex,
      onClick(e: React.MouseEvent) {
        if (disabled) {
          e.preventDefault();
          return;
        }

        if (onClick) {
          onClick(e);
        }
      },
    },
    sbs: {
      /* part of button styles can be overwritten by sbs prop */
      p: '0',
      lineHeight: '1.2',
      ...sbsProps,
      width: width ?? (sbsProps?.width || '100%'),
    },
  };

  if (isLink(boxProps)) {
    boxProps.elementProps = {
      ...boxProps.elementProps,
      href,
      target,
      'aria-disabled': disabled,
      role: 'button',
    };
  }

  if (isButton(boxProps)) {
    boxProps.elementProps = {
      ...boxProps.elementProps,
      disabled,
      type,
      'data-status': disabled ? 'inactive' : 'active',
    };
  }

  return (
    <StylishBox
      {...boxProps}
      className={cx(classNameStyles, classNameProp)}
    />
  );
};

export default Button;
