import { fetcher } from 'src/api/fetch';
import { ApiErrorShape } from 'src/api/types';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

interface RequestParams {
  phone: string;
  otp: string;
  password: string;
  password2: string;
}

interface RequestResult {
  errors: ApiErrorShape[];
}

const requestPostCreatePassword = async (params: RequestParams): Promise<RequestResult | null> => {
  const response = await fetcher(`/user/create_password/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(params),
  });

  return getErrorsFromResponse(response);
};

export default requestPostCreatePassword;
