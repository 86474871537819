import { ThemeOld } from 'src/styles/theme-old';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

const getStyles = (theme: ThemeOld) => {
  return css`
    .DesktopSwitchLocale__menuItem:not(:first-child) {
      margin-top: 12px;
    }
    .DesktopSwitchLocale__menuLink {
      ${typography.text}
      text-decoration: none;
    }
    .DesktopSwitchLocale__menuLinkActive,
    .DesktopSwitchLocale__menuLink:hover,
    .DesktopSwitchLocale__menuLink:active {
      color: ${theme.colors.mainBrand};
    }
  `;
};

export default getStyles;
