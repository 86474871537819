import { useEffect, useState } from 'react';
import ChangePasswordForm from 'src/components/ChangePasswordForm/ChangePasswordForm';
import useLocalize from '../../../../services/localize/useLocalize';

export type FieldKeys = 'new_password' | 'new_password_confirm';

export type FieldsState = {
  new_password: string;
  new_password_confirm: string;
}

interface Props {
  fields: FieldsState;
  onChangeField: (field: FieldKeys, newValue: string) => void;
  onSubmit: (data: FieldsState) => void;
}

const NewPasswordForm = (props: Props) => {
  const { onSubmit, onChangeField, fields } = props;
  const localize = useLocalize();

  const [fieldsErrorMap, setFieldsErrorMap] = useState({
    new_password_confirm: '',
  });

  const { new_password, new_password_confirm } = fields;

  const hasError = Object.values(fieldsErrorMap).some(i => i.length);

  const submitIsDisabled = !new_password.length
  || !new_password_confirm.length
  || hasError
  || new_password_confirm.length < new_password.length;

  //live validation
  useEffect(() => {
    const newPasswordConfirmTouched = new_password_confirm.length >= new_password.length;
    const newPasswordsNotEqual = (new_password !== new_password_confirm) && newPasswordConfirmTouched;

    setFieldsErrorMap({
      new_password_confirm: newPasswordsNotEqual ? localize('account.new_password_confirm_error') : '',
    });
  }, [new_password, new_password_confirm, localize]);

  return (
    <ChangePasswordForm
      fields={fields}
      fieldsErrorMap={fieldsErrorMap}
      onFieldChange={onChangeField}
      onSubmit={() => onSubmit(fields)}
      submitIsDisabled={submitIsDisabled}
    />
  );
};

export default NewPasswordForm;
