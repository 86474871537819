import { phone } from 'src/styles/media';
import css from 'styled-jsx/css';

const getStyles = theme => {
  return css`
    .ContactInformation {
      font-family: ${theme.fonts.default};
      max-width: 170px;
    }

    .ContactInformation__item {
      font-size: 16px;
      line-height: 1.5;
      color: ${theme.colors.text};
      margin-bottom: 16px;
    }

    .ContactInformation__itemAction {
      color: ${theme.colors.text};
      text-decoration: none;
    }

    @media ${phone} {
      .ContactInformation {
        max-width: 100%;
      }
    }
  `;
};

export default getStyles;
