export function parseCookieString(cookieString: string): { [name: string]: string } {
  const cookieObject = {};

  if (!cookieString) {
    return cookieObject;
  }

  const cookiePairs = cookieString.split('; ');

  for (const pair of cookiePairs) {
    const [key, value] = pair.split('=');
    cookieObject[key] = value;
  }

  return cookieObject;
}
