import { Fragment, ReactNode } from 'react';
import IconFont from 'src/ui-kit/IconFont';
import getStyles from './Dropdown.styles';
import useThemeOld from 'src/services/theme/useThemeOld';

interface Props {
  label: ReactNode;
  children: ReactNode;
  dataMarker?: string;
  position?: 'left' | 'center' | 'right';
}

const Dropdown = (props: Props) => {
  const { children, label, dataMarker = 'dropdown', position = 'center' } = props;
  const theme = useThemeOld();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <div
        className="Dropdown"
        data-marker={dataMarker}
        data-testid="dropdown"
      >
        <div className="Dropdown__selected" data-marker={`${dataMarker}-button`}>
          <span className="Dropdown__label">{label}</span>
          <span className="Dropdown__icon">
            <IconFont name='caret-bottom' size='11px'/>
          </span>
        </div>
        <div
          className={`Dropdown__menu Dropdown__menu_${position}`}
          data-marker={`${dataMarker}-menu`}
        >
          <div className="Dropdown__menuInner">
            {children}
          </div>
        </div>
        <style jsx>{styles}</style>
      </div>
    </Fragment>
  );
};

export default Dropdown;
