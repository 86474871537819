import { ThemeOld } from 'src/styles/theme-old';
import css from 'styled-jsx/css';

const getStyles = (theme: ThemeOld) => {
  return css`
    .SocialItem {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      text-decoration: none;

      border: 1px solid ${theme.colors.brandLightGrey9};
      color: ${theme.colors.text};
      border-radius: 50%;
      transition: 0.2s border-color ease-in-out;
      background-color: ${theme.colors.white};
    }

    .SocialItem:hover {
      border-color: ${theme.colors.text};
    }
  `;
};

export default getStyles;
