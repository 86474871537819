export const colors = {
  text: '#2C312C',
  mainBrand: '#1EB763',
  hoverBrand: '#119F51',
  hoverBrand2: '#11994E',
  activeBrand: '#098641',
  activeBrand2: '#00904A',
  accentBrand: '#FFCF1B',


  brandLightGrey1: '#efefef',
  brandLightGrey2: '#dddddd',
  brandLightGrey3: '#d8d8d8',
  brandLightGrey4: '#f0f6f0',
  brandLightGrey5: '#f8f8f8',
  brandLightGrey6: '#DADADA',
  brandLightGrey7: '#D2D2D2',
  brandLightGrey8: '#9f9f9f',
  brandLightGrey9: '#D4D4D4',

  lightGrey1: '#F5F6F7',
  lightGrey2: '#E8ECE9',

  doveGray: '#6A6A6A',
  mineShaft: '#303030',
  darkGrey: '#b4b4b4',

  darkenGrey1: '#9b9b9b',
  darkenGrey2: '#232926',
  darkenGrey3: '#616d65',
  darkenGrey4: '#979797',

  white: '#fff',
  black: '#000',
  blackStroke: '#222421',
  attention: '#f79a00',
  orange: '#ffcf1b',
  green: '#45b759',
  alabaster: '#fafafa',
  silverChalice: '#9f9f9f',
  barleyWhite: '#FFF2D1',

  red: '#ff5252',
  darkRed: '#e64242',
  lightRed: '#fff2f2',

  transparent: 'transparent',

  invalidBackground: '#FAF0F0',
  invalidBorder: '#d79f9f',

  whiteSmoke: '#F2F2F2',
  solitude: '#F5F6F7',
  malachite: '#0EC545',
  salem: '#0B9D4D',
  pigmentGreen: '#098742',
  suvGrey: '#8F8F8F',
  hex: '#43483A',
  nightRider: '#333333',
  gray: {
    300: '#D5D5D5',
  },
};

export const fonts = {
  default: '"Mulish", Verdana, Arial, sans-serif',
  title: '"Twentytwelve Sans G", Verdana, Arial, sans-serif',
};

export const zIndex = {
  modalOverlay: 10,
  header: 8,
  zendesk: 9,
};

export const commonParams = {
  borderRadius: '3px',
};

const themeOld = {
  colors,
  fonts,
  zIndex,
  commonParams,
};

export type ThemeOld = typeof themeOld;

export default themeOld;
