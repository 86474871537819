import React, { Fragment } from 'react';
import cn from 'classnames';
import getStyles from './LoadingBarIndicator.styles';

interface Props {
  isUnderContainer?: boolean;
}

function LoadingBarIndicator(props: Props) {
  const styles = getStyles();
  return (
    <Fragment>
      <div
        data-testid='LoadingBarIndicator'
        data-marker='Loading Bar Indicator'
        className={cn(
          'LoadingBarIndicator', {
          'LoadingBarIndicator_isUnderContainer': props.isUnderContainer,
        })}
      />
      <style jsx>{styles}</style>
    </Fragment>
  );
}

export default LoadingBarIndicator;
