import { ThemeOld } from 'src/styles/theme-old';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

const getStyles = (theme: ThemeOld) => {
  return css`
    .FormLabel {
      ${typography.h9}
      margin-bottom: 4px;
      display: block;
    }

    .FormLabel_aligned {
      text-align: start;
    }

    .FormLabel__requiredMark {
      color: ${theme.colors.red};
    }
  `;
};

export default getStyles;
