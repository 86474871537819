import css from 'styled-jsx/css';
import { desktop, phone, phone_tablet, tablet } from '../../styles/media';

export default css`
  .Error {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 90px 15px 90px;

    height: 100%;

    font-family:  "Mulish", Verdana, Arial, sans-serif;
  }

  .Error__image {
    max-width: 100%;
    height: auto;
  }

  .Error__number {
    color: #1EB763;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .Error__title {
    margin-top: 22px;
    margin-bottom: 32px;

    color: #2C312C;
    font-family: 'Twentytwelve Sans G', sans-serif;
    font-size: 50px;
    line-height: 48px;
  }

  .Error__text {
    font-size: 16px;
    line-height: 26px;
  }

  .Error__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 150px;
    height: 48px;

    background: #1EB763;

    color: #FFFFFF;
    font-size: 16px;
    text-decoration: none;
  }

  .Error__button:hover {
    background: #119F51;
  }

  .Error__button:active {
    background: #098641;
  }

  @media ${tablet} {
    .Error {
      padding-top: 39px;
    }

    .Error__imageBox {
      max-width: 368px;
    }

    .Error__textBox {
      margin-top: 101px;
    }

    .Error__title {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }

  @media ${phone} {
    .Error {
      padding-top: 20px;
    }

    .Error__imageBox {
      max-width: 230px;
    }

    .Error__textBox {
      max-width: 550px;
      margin-top: 11px;
    }

    .Error__title {
      margin-top: 17px;
      margin-bottom: 17px;

      font-size: 34px;
      line-height: 48px;
    }
  }

  @media ${desktop} {
    .Error__imageBox {
      margin-right: 60px;
    }
  }

  @media ${phone_tablet} {
    .Error {
      padding-bottom: 50px;
      flex-direction: column;
    }
  }
`;
