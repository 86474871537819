import Cookies from 'js-cookie';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'public/static/fonts/font-faces.css';
import 'public/static/icons/icons.css';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Category } from 'src/api/types';
import { getCategories } from 'src/api/ubercatalogRequests';
import { ThemeProvider } from 'src/ui-kit/theme';
import { parseCookieString } from 'src/utils/js-helpers/parseCookieString';
import getRedirectPath, { handlers } from 'src/utils/redirectPath/getRedirectPath';
import { SWRConfig } from 'swr';
import fetchTranslations from '../api/fetchTranslations';
import AuthSynchronizerWatcher from '../components/AuthSynchronizerWatcher/AuthSynchronizerWatcher';
import { NotFoundPage } from '../components/ErrorPages';
import GtmScript from '../components/GtmScript/GtmScript';
import OpenGraphMeta from '../components/OpenGraphMeta';
import { BASE_URL, COUNTRY, CROSS_DOMAIN_GTM_ID, DEFAULT_LANG, GTM_ID, IS_SERVER, RECAPTCHA_PUBLIC_KEY, WITH_UBERCATALOG, ZENDESK_KEY } from '../data/constants';
import AppUserDeliveryProvider from '../modules/address-management/AddressManagementRetailsProvider';
import { Messengers } from '../modules/messengers';
import ModalsList from '../modules/modals/ModalsList';
import { ServicesProvider } from '../services';
import getStyles from '../styles/global.styles';
import themeOld from '../styles/theme-old';
import { updateAffiliateProgramsCookie } from '../utils/affiliatePrograms';
import { sendDimensionSet } from '../utils/enhancedEcommerce';

interface CustomProps {
  lang: string;
  isNotFound?: boolean;
  translations?: any;
  host?: string;
  categories: Category[];
}

type InitialProps = CustomProps & AppInitialProps;

function MyApp(props: AppProps & CustomProps) {
  const {
    Component,
    pageProps,
    isNotFound,
    lang,
    translations,
    host,
    categories,
  } = props;

  const { query, pathname } = useRouter();

  useEffect(
    () => {
      const gaCookie = Cookies.get('_ga');
      const clientId = gaCookie && gaCookie.slice(6);
      sendDimensionSet(clientId);
      updateAffiliateProgramsCookie(query, host!);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const styles = getStyles(themeOld);

  if (pathname === '/auth') {
    return <Component {...pageProps} />;
  }

  return (
    <ThemeProvider skin='zakaz'>
      <SWRConfig value={{ errorRetryCount: 1, dedupingInterval: 600000 }}>
        <GoogleReCaptchaProvider
          reCaptchaKey={RECAPTCHA_PUBLIC_KEY}
          language={lang}
        >
          <ServicesProvider
            initLang={lang}
            translations={translations}
            initCategories={categories}
          >
            <Head>
              <title key='title'>{`zakaz.${COUNTRY}`}</title>
              <link rel="alternate" hrefLang={`${lang}-ua` } href={`${BASE_URL}/${lang}/`} />
              <link rel="alternate" hrefLang="x-default" href={`${BASE_URL}/uk/`} />
            </Head>
            <AppUserDeliveryProvider language={lang}>
              {isNotFound
                ? <NotFoundPage />
                : <Component {...pageProps} />
              }

              <ModalsList />

              {
                COUNTRY === 'ua' && <Messengers zendeskKey={ZENDESK_KEY} />
              }
              <AuthSynchronizerWatcher />
              <style jsx global>{styles}</style>
            </AppUserDeliveryProvider>
            <GtmScript gtmId={GTM_ID} />
            <GtmScript gtmId={CROSS_DOMAIN_GTM_ID} />
            <OpenGraphMeta lang={lang} />
          </ServicesProvider>
        </GoogleReCaptchaProvider>
      </SWRConfig>
    </ThemeProvider>
  );
}

function getFallbackLang(asPath: string): string {
  return asPath.match(/^\/(\w{2})\//)?.[1] || DEFAULT_LANG;
}


MyApp.getInitialProps = async (context: AppContext): Promise<InitialProps> => {
  const { ctx, router, Component } = context;
  const { req: req_, res: res_, query } = ctx;
  const req = req_!;
  const res = res_!;
  const { asPath } = router;
  let isNotFound = false;
  let host: string | undefined;
  let categories: Category[] = [];

  if (IS_SERVER) {
    const cookie = req.headers?.cookie || '';
    const parsedCookies = parseCookieString(cookie);
    const redirectResult = await getRedirectPath(asPath, handlers, {
      preferredLanguage: parsedCookies['language'],
    });

    if (redirectResult.isModified) {
      if (redirectResult.isNotFound) {
        res.statusCode = 404;
        isNotFound = true;
      } else {
        /** @todo hack for now for static re-generation */
        if (res.writeHead) {
          const { redirectType, path } = redirectResult;

          res.writeHead(redirectType, { Location: path });
          res.end();
        }

        return {} as InitialProps;
      }
    }
    host = req.headers.host as string;
  }

  /**
   * Read this before making any changes
   * https://zakaz.atlassian.net/wiki/spaces/DEVEL/pages/2761424898
   * fallbackLang because Next.js doesn't provide query on error pages
  */
  const lang = query.lang as string || getFallbackLang(asPath);

  if (!IS_SERVER) {
    const appProps = await App.getInitialProps(context);
    return {
      ...appProps,
      categories,
      lang,
    };
  }

  const response = await fetchTranslations(lang);

  if (response.status === 'fail') {
    res.statusCode = 500;
    res.end();
    return {} as InitialProps;
  }

  const translations = response.data;

  if(WITH_UBERCATALOG) {
    categories = await getCategories(lang);
  }

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  return {
    pageProps,
    lang,
    translations,
    categories,
    isNotFound,
    host,
  };
};


export default MyApp;
