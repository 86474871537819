import { Fragment } from "react";
import FlexList from "src/components/FlexList/FlexList";
import useLocalize from "src/services/localize/useLocalize";
import { Typography } from "src/ui-kit/Typography";
import { RetailInfo } from "../../types";
import RetailInfoCard from "../RetailInfoCard/RetailInfoCard";

interface RetailsInfoUnavailableListProps {
  items: RetailInfo[];
  onRetailInfoCardClick: (isAvailable: boolean) => void;
  onClickOpenAm: () => void;
  size: 'small' | 'medium' | 'large';
}

const RetailsInfoUnavailableList = (props: RetailsInfoUnavailableListProps) => {
  const { onClickOpenAm, onRetailInfoCardClick, items, size } = props;
  const localize = useLocalize();

  return (
    <Fragment>
      <Typography sbs={{ mb: '16px' }}>
        {localize('unavailable_retails_text_1')}
        {', '}
        <Typography
          element='span'
          color='primary'
          elementProps={{ onClick: onClickOpenAm }}
          sbs={{cursor: 'pointer'}}
          data-marker='Choose Another Delivery'
        >
          {localize('unavailable_retails_text_2')}
      </Typography>
      </Typography>

      <div data-marker='Unavailable Retails'>
        <FlexList
          size={size}
          data={items}
          renderItem={(retailInfo) => (
            <RetailInfoCard
              isAvailable={false}
              size={size}
              retailInfo={retailInfo}
              onClick={() => onRetailInfoCardClick(false)}
              dataStatus='inactive'
            />
          )}
        />
      </div>
    </Fragment>
  );
};

export default RetailsInfoUnavailableList;
