let topPosition = 0;

export function disableBodyScroll() {
  topPosition = window.pageYOffset;
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.top = `-${topPosition}px`;
  document.body.style.width = '100%';
  document.body.style.paddingRight = '' + getScrollbarWidth(); // remove content jump when modal is open
}

export function enableBodyScroll() {
  if (!document.body.classList.contains('Modal_opened')) {
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
    document.body.style.top = '0';
    document.body.style.width = '100%';
    window.scrollTo(0, topPosition);
    topPosition = 0;
    document.body.style.paddingRight = '0';
  }
}

function getScrollbarWidth(): number {
  return window.innerWidth - document.documentElement.clientWidth;
}

/** based on the article:  https://markus.oberlehner.net/blog/simple-solution-to-prevent-body-scrolling-on-ios/ */
