import { ComponentProps } from 'react';
import FormLabel, { FormLabelProps } from '../FormLabel/FormLabel';
import Input, { InputProps } from '../Input/Input';

interface FormFieldProps {
  label: string;
  id: string;
  value: ComponentProps<'input'>['value'];
  onChange: ComponentProps<'input'>['onChange'];
  labelProps?: Partial<FormLabelProps>;
  inputProps?: Partial<InputProps>;
}

const FormField = (props: FormFieldProps) => {
  const {
    id,
    label,
    value,
    onChange,
    labelProps,
    inputProps,
  } = props;

  return (
    <div className="FormField">
      <FormLabel
        htmlFor={ id }
        labelText={ label }
        { ...labelProps }
      />

      <Input
        id={ id }
        value={ value }
        onChange={ onChange }
        { ...inputProps }
      />
    </div>
  );
};

export default FormField;
