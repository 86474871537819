import cn from 'classnames';
import { ComponentProps, forwardRef, Fragment } from 'react';
import { useTheme } from 'src/ui-kit/theme';
import getStyles from './Input.styles';

export interface InputProps extends Omit<ComponentProps<'input'>,  'className' | 'ref'> {
  dataMarker?: string;
  testId?: string;
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    dataMarker,
    testId,
    error,
    ...restProps
  } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Fragment>
      <input
        type={type}
        className={cn('Input', {
          'Input_error': !!error,
        })}
        data-marker={ dataMarker }
        data-testid={ testId }
        ref={ref}
        { ...restProps }
      />
      {error && (
        <div className="Input__errorMessage" data-testid='input-error-message'>
          {error}
        </div>
      )}
      <style jsx>{ styles }</style>
    </Fragment>
  );
});

Input.displayName = 'Input';

export default Input;
