import useModals from 'src/modules/modals/useModals';
import useLocalize from 'src/services/localize/useLocalize';
import IconFont from 'src/ui-kit/IconFont';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Typography } from 'src/ui-kit/Typography';
import { useTheme } from 'src/ui-kit/theme';
import { styles } from './LoginButton.styles';

interface Props {
  dataMarker?: string;
}

const LoginButton = (props: Props) => {
  const { dataMarker } = props;
  const { openModal } = useModals();
  const localize = useLocalize();
  const theme = useTheme();

  const handleClick = () => openModal('authorizationModal');

  return (
    <button
      data-marker={dataMarker}
      onClick={handleClick}
      data-testid='login-button'
      className='LoginButton'
    >
      <Typography
        element='span'
        variant='text6'
        color='primary'
        sbs={{
          hover: {
            color: theme.stylishLink.accented.colorHover,
          },
          lineHeight: '1',
        }}
      >
        <IconFont
          name='user'
          size={{
            general: '14px',
            phone: '18px',
          }} />

        <StylishBox
          element='span'
          sbs={{
            display: {
              phone: 'none',
            },
            ml: '7px',
          }}
        >
          {localize('general.login-2')}
        </StylishBox>
      </Typography>
      <style jsx>{styles}</style>
    </button>
  );
};

export default LoginButton;
