import { ChangeEventHandler, createRef, KeyboardEventHandler, RefObject, useEffect, useMemo, useState } from 'react';
import { ApiErrorShape } from 'src/api/types';
import { useLocalize } from 'src/services/localize/useLocalize';
import useThemeOld from 'src/services/theme/useThemeOld';
import Button from 'src/ui-kit/Button';
import Input from 'src/UIKitOld/Input/Input';
import getDataStatus from 'src/utils/getDataStatus';
import CountdownMessage from './components/CountdownMessage';
import { getStyles } from './ConfirmPhoneByCode.styles';

export interface ConfirmPhoneByCodeProps {
  phone: string;
  length?: number;
  errors: ApiErrorShape[];
  retryAfterSeconds?: number;
  onRepeat: () => void;
  onStepBack: () => void;
  onComplete: (otp: string) => void;
}

export default function ConfirmPhoneByCode(props: ConfirmPhoneByCodeProps) {
  const {
    phone,
    length = 4,
    onRepeat,
    onStepBack,
    onComplete,
    errors = [],
    retryAfterSeconds = null,
  } = props;

  const localize = useLocalize();
  const theme = useThemeOld();
  const styles = getStyles(theme);

  const [values, setValues] = useState<string[]>(new Array(length).fill(''));
  const [countdownSeconds, setCountdownSeconds] = useState<number | null>(retryAfterSeconds);
  const [canRepeat, setCanRepeat] = useState(!retryAfterSeconds);

  const inputs = useMemo<RefObject<HTMLInputElement>[]>(
    () => {
      const result: RefObject<HTMLInputElement>[] = [];

      for (let index = 0; index < length; index++) {
        result[index] = createRef<HTMLInputElement>();
      }

      return result;
    },
    [length],
  );

  const otpIsFull = values.join('').length === length;
  const shouldShowError = !!errors?.length && otpIsFull;

  useEffect(() => {
    inputs[0]?.current?.focus();
  }, [inputs]);

  useEffect(() => {
    setCountdownSeconds(retryAfterSeconds);
  }, [retryAfterSeconds]);

  function updateValueByIndex(value: string, index: number) {
    setValues(prevValues => prevValues.map((prevValue, prevIndex) => prevIndex !== index ? prevValue : value));
  }

  const handleChange = (index: number): ChangeEventHandler<HTMLInputElement> => event => {
    const { value, validity } = event.target;

    if (validity.valid) {
      const newValues = [...values];
      newValues[index] = value;

      setValues(newValues);

      if (index < length - 1 && value.length) {
        // Set focus on next
        inputs[index + 1]?.current?.focus();
      }

      const otp = newValues.join('');

      if(otp.length === length) {
        onComplete(otp);
      }
    }
  };

  const handleKeyDown = (index: number): KeyboardEventHandler<HTMLInputElement> => event => {
    if (event.key === 'Backspace') {
      event.preventDefault();

      if (index > 0) {
        if (values[index] !== '') {
          inputs[index]?.current?.focus(); // remain in a cell if it was not empty before correcting
        } else {
          inputs[index - 1]?.current?.focus(); // go to previous cell if it's empty
        }
      }

      updateValueByIndex('', index);
    } else if (!(event.key >= '0' && event.key <= '9')) {
      event.preventDefault();
    }
  };

  const handleRepeat = (): void => {
    setValues(new Array(length).fill(''));
    setCountdownSeconds(null);
    setCanRepeat(false);
    onRepeat();
  };

  const handleEndCounting = (): void => {
    setCountdownSeconds(null);
    setCanRepeat(true);
  };

  return (
    <div className='ConfirmPhoneByCode'>
      <div className='ConfirmPhoneByCode__title'>
        <span>{`${localize('user.sms-code.info.begin')} `}</span>
        <span className='ConfirmPhoneByCode__timeInterval'>{localize('user.sms-code.info.time_interval')}</span>
        <span>{` ${localize('user.sms-code.info.end')}`}</span>
        <span className='ConfirmPhoneByCode__titlePhone'>{` ${phone}`}</span>
      </div>

      <div className="ConfirmPhoneByCode__editPhone">
        <Button
          onClick={onStepBack}
          type='button'
          dataMarker='Edit phone number'
          variant="ghost"
          width="auto"
        >
          <span>{localize('user.phone.edit')}</span>
        </Button>
      </div>


      <div className='ConfirmPhoneByCode__sms'>
        <span>{localize('user.sms-code.enter')}</span>

        <div className='ConfirmPhoneByCode__inputs'>
          {values.map((_, index) => (
            <Input
              key={index}
              ref={inputs[index]}
              onKeyDown={handleKeyDown(index)}
              onChange={handleChange(index)}
              value={values[index]}
              style={{ width: `${100 / (length + 1)}%` }}
              type='number'
              min={0}
              max={9}
              pattern='[0-9]{1}'
              onCopy={() => false}
              onPaste={() => false}
              data-marker={`Enter code from SMS_${index}`}
              aria-label={`${localize('digit')} ${index + 1}`}
            />
          ))}
        </div>
      </div>

      { shouldShowError && (
        <div
          className='ConfirmPhoneByCode__error form-control__error'
          data-testid='otp_error'
        >
          {localize(`error.code.${errors[0].error_code}`)}
        </div>
      )}

      <div className="ConfirmPhoneByCode__buttons">
        <Button
          onClick={handleRepeat}
          type='button'
          dataMarker='Send code again'
          data-status={getDataStatus(canRepeat)}
          disabled={!canRepeat}
          variant="ghost"
        >
          <span>{localize('user.sms-code.do-not-get')}</span>
        </Button>
      </div>

      {!!countdownSeconds && (
        <div className="ConfirmPhoneByCode__countdown">
          <CountdownMessage
            secondsStart={countdownSeconds}
            onEndCounting={handleEndCounting}
          />
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
}
