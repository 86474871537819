import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTheme } from 'src/ui-kit/theme';
import savePreferredLanguage from 'src/utils/js-helpers/savePreferredLanguage';
import { LANGUAGES } from '../../../../data/constants';
import useLang from '../../../../services/language/useLang';
import { useLocalize } from '../../../../services/localize/useLocalize';
import getStyles from './SwitchLocale.styles';

const SwitchLocale = () => {
  const currentLanguage = useLang();
  const theme = useTheme();
  const { asPath = '' } = useRouter();
  const pathRegExp = new RegExp(`^/${currentLanguage}`);

  const styles = getStyles(theme);
  const localize = useLocalize();

  return (
    <div className='SwitchLocale'>
      {
        LANGUAGES.map(language => (
          <a
            key={language}
            href={asPath.replace(pathRegExp, `/${language}`)}
            onClick={() => savePreferredLanguage(language)}
            className={
              classNames(
                'SwitchLocale__link',
                {
                  active: language === currentLanguage,
                },
              )
            }
          >
            <span className='SwitchLocale__linkText'>{localize(`lang_${language}`)}</span>
          </a>
        ))
      }

      <style jsx>{styles}</style>
    </div>
  );
};

export default SwitchLocale;
