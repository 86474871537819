import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';

const getStyles = (theme: Theme) => {
  return css`
    .SwitchLocale {
      display: flex;
      font-family: ${theme.base.fontFamily.normal};
      line-height: 24px;
      font-size: 14px;
    }
    .SwitchLocale__link {
      position: relative;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: 0.2s color ease-in-out;
      color: ${theme.base.color.gray600};
    }

    .SwitchLocale__link:not(:last-child) {
      margin-right: 33px;
    }

    .SwitchLocale__link:not(:last-child)::after {
      content: '';
      width: 1px;
      height: 16px;
      position: absolute;
      right: -16px;
      top: 4px;
      background: ${theme.base.color.gray300};
    }

    .SwitchLocale__linkText {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .SwitchLocale__link.active {
      font-weight: 600;
      color: ${theme.base.color.dark};
    }

    .SwitchLocale__link.active {
      pointer-events: none;
    }
  `;
};

export default getStyles;
