import cn from 'classnames';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import savePreferredLanguage from 'src/utils/js-helpers/savePreferredLanguage';
import { LANGUAGES } from '../../../../data/constants';
import useLang from '../../../../services/language/useLang';
import { useLocalize } from '../../../../services/localize/useLocalize';
import useThemeOld from '../../../../services/theme/useThemeOld';
import Dropdown from '../Dropdown/Dropdown';
import getStyles from './DesktopSwitchLocale.styles';

const DesktopSwitchLocale = () => {
  const currentLanguage = useLang();
  const theme = useThemeOld();

  const { asPath = '' } = useRouter();
  const pathRegExp = new RegExp(`^/${currentLanguage}`);

  const styles = getStyles(theme);
  const localize = useLocalize();

  return (
    <Fragment>
      <Dropdown
        label={localize(`lang_${currentLanguage}`)}
        dataMarker="lang"
      >
        {LANGUAGES.map(language => (
          <div
            key={language}
            className="DesktopSwitchLocale__menuItem"
          >
            <a
              href={asPath.replace(pathRegExp, `/${language}`)}
              onClick={() => savePreferredLanguage(language)}
              className={cn('DesktopSwitchLocale__menuLink', {
                DesktopSwitchLocale__menuLinkActive: language === currentLanguage,
              })}
              data-marker={`lang-link-${language}`}
            >
              <span className='DesktopSwitchLocale__linkText'>
                {localize(`lang_${language}`)}
              </span>
            </a>
          </div>
        ))}
      </Dropdown>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default DesktopSwitchLocale;
