import css from 'styled-jsx/css';

const getStyles = css`
  .Socials {
    display: flex;
  }

  .Socials__item {
    margin-right: 16px;
  }
`;

export default getStyles;
