import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { Delivery } from '../../delivery/types';

export const getDeliveryTypeText = (userDelivery: Delivery | null, localize: LocalizeFunc): string => {
  if (userDelivery === null) {
    return '';
  }

  if (userDelivery.type === 'plan') {
    return localize('am_bar_plan');
  }

  if (userDelivery.type === 'pickup') {
    return localize('am_bar_pickup');
  }

  if (userDelivery.type === 'nova_poshta' || userDelivery.type === 'nova_poshta_address') {
    return localize('am_bar_np');
  }

  throw new Error('Unexpected delivery type');
};
