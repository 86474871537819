import { Fragment } from 'react';
import useThemeOld from 'src/services/theme/useThemeOld';
import IconFont from '../../ui-kit/IconFont';
import getStyles from './SocialItem.styles';
import { Social } from './types';

const SocialItem = (props: Social) => {
  const theme = useThemeOld();
  const styles = getStyles(theme);

  const { url, type } = props;
  return (
    <Fragment>
      <a href={url} className={`SocialItem SocialItem_${type}`} target="_blank" rel='noreferrer nofollow'>
        <IconFont name={type} />
      </a>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default SocialItem;
