import { QueryString } from '../../../api/types';
import { Delivery } from '../../delivery/types';

const getRetailInfoQueryStringForLink = (userDelivery: Delivery | null): QueryString => {

  if (userDelivery === null) {
    return '';
  }

  return `?delivery=${encodeURIComponent(JSON.stringify(userDelivery))}`;
};

export default getRetailInfoQueryStringForLink;
