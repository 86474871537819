import { KeyboardEventHandler } from "react";
import { ApiErrorShape } from "src/api/types";
import NotificationBar from "src/UIKitOld/NotificationBar/NotificationBar";
import { personalizeElement } from "src/utils/personalize";
import useLocalize from "../../services/localize/useLocalize";
import Button from 'src/ui-kit/Button';
import PhoneInput, { PhoneInputChangeHandler } from "../../UIKitOld/PhoneInput/PhoneInput";
import getStyles from './EnterPhoneForm.styles';

interface Props {
  onSubmit(): void;
  phone: string;
  onChange: PhoneInputChangeHandler;
  error?: ApiErrorShape;
  buttonText?: string;
}

const EnterPhoneForm = (props: Props) => {
  const styles = getStyles();
  const { phone, onChange, onSubmit, error, buttonText } = props;
  const localize = useLocalize();
  const submitIsDisabled = !!error || phone.length <= 3;
  const errorCode = error?.error_code;

  const handleEnter: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter' && !submitIsDisabled) {
      onSubmit();
    }
  };

  const hasPhoneCommonError = error && errorCode !== 4199;
  const hasPhoneExistsError = error && errorCode === 4199;

  return (
    <div className="EnterPhoneForm" data-testid='EnterPhoneForm' onKeyDown={handleEnter}>
      <div className="EnterPhoneForm__input">
        <PhoneInput
          inputName="additional-phone"
          value={phone}
          onChange={onChange}
          label={localize('general.mobile')}
          autoFocus
          errorText={hasPhoneCommonError ? localize(`error.code.${errorCode}`) : ''}
          dataMarker="Mobile Phone"
        />
      </div>

      {hasPhoneExistsError && (
        <div className="EnterPhoneForm__loginError" data-testid="login-exist-error">
          <NotificationBar>
            {
              personalizeElement(
                localize('login_error_4199'),
                [
                  <strong key="phone">{error?.details?.current}</strong>,
                ],
              )
            }
          </NotificationBar>
        </div>
      )}

      <div className="EnterPhoneForm__button">
        <Button
          variant="primarySmall"
          disabled={submitIsDisabled}
          onClick={onSubmit}
          dataMarker='Submit'
        >
          {buttonText || localize('general.continue')}
        </Button>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default EnterPhoneForm;
