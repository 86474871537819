import { CatalogProductBase } from 'src/api/types';
import { CROSS_DOMAIN_GTM_ID, CURRENCY, GTM_ID } from '../data/constants';

export const sendDimensionSet = (client_id?: string) => {
  if (!client_id) {
    return;
  }

  window.dataLayer.push({
    STORE_ID: '4821000',
    CHAIN: 'zakaz.ua',
    CLIENT_ID: client_id,
    event: 'dimensions_set',
  });
};

export const sendBannerImpression = params => {
  const { bannerId, bannerName, bannerPosition } = params;

  window.dataLayer.push({
    ecommerce: {
      promoView: {
        promotions: [{
          id: bannerId,
          name: bannerName,
          position: bannerPosition,
         }],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory: 'Enhanced Ecommerce',
    gtmUaEventAction: 'Promotion Impression',
    gtmUaEventNonInteraction: 'True',
  });
};

export const sendBannerClick = params => {
  const { bannerId, bannerName, bannerPosition, destinationUrl, targetType } = params;

  const gtmMap = {
    [GTM_ID]: false,
    [CROSS_DOMAIN_GTM_ID]: false,
  };

  window.dataLayer.push({
    ecommerce: {
      promoClick: {
        promotions: [
         {
           'id': bannerId,
           'name': bannerName,
           'position': bannerPosition,
         }],
      },
    },
    event: 'gtmUaEvent',
    gtmUaEventCategory:'Enhanced Ecommerce',
    gtmUaEventAction:'Promotion Click',
    gtmUaEventNonInteraction:'True',
    eventCallback: function(gtmId) {
      gtmMap[gtmId] = true;
      const canChangeLocation = Object.values(gtmMap).every(id => id);
      if (canChangeLocation && destinationUrl) {
         window.open(destinationUrl, targetType);
      }
    },
  });
};

export const sendHrHeaderClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': 'HR_Landing_header ',
  });
};

export const sendHrFooterClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': 'HR_Landing_footer',
  });
};

export const sendHrContactsClick = () => {
  window.dataLayer.push({
    'event': 'gtmUaEvent',
    'gtmUaEventCategory': 'Behaviour',
    'gtmUaEventAction': 'Click',
    'gtmUaEventLabel': 'HR_Landing_contacts',
  });
};

export type ProductPlace = 'Product card - similar' | 'Product card - buy with' | 'category';

export const sendProductClick = (product: CatalogProductBase, place: ProductPlace) => {
  window.dataLayer.push({
    event: "view_item",
    place,
    ecommerce: {
      currency: CURRENCY,
      product_ids: [product.ean],
      items: [
        {
          item_id: product.ean,
          item_name: product.title,
          item_is_in_stock: product.in_stock ? '1' : '0',
          item_variant: product.unit === 'kg' ? 'By weight' : 'By piece',
          price_from: product.price_details.price_from,
          price_to: product.price_details.price_to,
        },
      ],
    },
  });
};
