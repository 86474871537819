import { phone } from 'src/styles/media';
import Skeleton from '../../../../components/Loaders/Skeleton/Skeleton';

const AddressManagementBarSkeleton = () => {
  return (
    <div className='AddressManagementBarSkeleton'>
      <Skeleton />
      <style jsx>{`
        .AddressManagementBarSkeleton {
          height: 80px;
        }

        @media ${phone} {
          .AddressManagementBarSkeleton {
            height: 78px;
          }
        }
      `}</style>
    </div>
  );
};

export default AddressManagementBarSkeleton;
