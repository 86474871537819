import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { ANDROID_APP_ID, COUNTRY, IOS_APP_ID } from '../../data/constants';

export type Platform = 'ios' | 'android';

export type AppLinks = {
  [key in Platform]: string | null;
};

const mainPartAppLinks: AppLinks = {
  android: ANDROID_APP_ID ? `https://play.google.com/store/apps/details?id=${ANDROID_APP_ID}` : null,
  ios: IOS_APP_ID ? `https://apps.apple.com/${COUNTRY}/app/id${IOS_APP_ID}` : null,
};

export function useAppLinks(): AppLinks {
  const { query } = useRouter();

  const [appLinkAndroid, setAppLinkAndroid] = React.useState<string | null>(mainPartAppLinks.android);
  const [appLinkIos, setAppLinkIos] = React.useState<string | null>(mainPartAppLinks.ios);
  const [cid, setCid] = React.useState<string | null>(null);

  const timeoutId = useRef<number>();
  const intervalId = useRef<number>();

  function updateCid() {
    const cidFromCookies = Cookies.get('_ga')?.slice(6);

    if (cidFromCookies) {
      setCid(cidFromCookies);
    }

    intervalId.current = window.setInterval(() => {
      const cidFromCookies = Cookies.get('_ga')?.slice(6);

      if (cidFromCookies) {
        clearInterval(intervalId.current);
        setCid(cidFromCookies);
      }

    }, 300);

    timeoutId.current = window.setTimeout(function() {
      clearInterval(intervalId.current);
    }, 3000);
  }

  React.useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
      clearTimeout(timeoutId.current);
    };
  }, []);

  React.useEffect(() => {
    if (mainPartAppLinks.android || mainPartAppLinks.ios) {

      /**
       * Have to check for 'gci' (Google Client ID), because for users without this cookie
       * creation of new 'gci' will happen after loading GTM and GA scripts (245 KB).
       */
      updateCid();

      /**
       * Creating session cookies to store utm data while session lives
       */
      query.utm_source && Cookies.set('utm_source', query.utm_source as string);
      query.utm_medium && Cookies.set('utm_medium', query.utm_medium as string);
      query.utm_campaign && Cookies.set('utm_campaign', query.utm_campaign as string);

      /**
       * Docs about deep links forming rules can be found here:
       * https://zakaz.atlassian.net/browse/SHWEB-7081
       */
      const utmSource = Cookies.get('utm_source') || (cid ? 'google' : 'noutm');
      const utmMedium = Cookies.get('utm_medium') || (cid ? 'cpc' : 'noutm');
      const utmCampaign = Cookies.get('utm_campaign') || (cid ? 'gclid' : 'noutm');
      const partner = `zakaz.${COUNTRY}`;

      if (mainPartAppLinks.android) {
        setAppLinkAndroid(mainPartAppLinks.android + `&referrer=utm_source=${utmSource}&utm_medium=web_${utmMedium}&utm_campaign=${utmCampaign}_web_id_${cid || 'nocid'}_web_site_${partner}`);
      }

      if (mainPartAppLinks.ios) {
        setAppLinkIos(mainPartAppLinks.ios + `?utm_source=${utmSource}&utm_medium=web_${utmMedium}&utm_campaign=${utmCampaign}_web_id_${cid || 'nocid'}_web_site_${partner}`);
      }
    }
  }, [query.utm_source, query.utm_medium, query.utm_campaign, cid]);

  return {
    ios: appLinkIos,
    android: appLinkAndroid,
  };
}
