import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';

export const updateAffiliateProgramsCookie = (
  query: ParsedUrlQuery, host: string,
  ) => {

  if (
    !query.admitad_uid &&
    !query.aff_sub &&
    !query.utm_source &&
    !query.gclid
  ) {
    return;
  }

  const admitadCookie = Cookies.get('admitad_uid');
  const salesDoublerCookie = Cookies.get('sd_uid');
  const topHost = host.split(':')[0].split('.').slice(-2).join('.');

  if (query.admitad_uid) {
    setAdmitadCookie(query.admitad_uid as string, topHost);
    salesDoublerCookie && removeSalesDoublerCookie(topHost);
  }
  else if (query.aff_sub) {
    setSalesDoublerCookie(query.aff_sub as string, topHost);
    admitadCookie && removeAdmitadCookie(topHost);
  }
  else {
    salesDoublerCookie && removeSalesDoublerCookie(topHost);
    admitadCookie && removeAdmitadCookie(topHost);
  }
};

const setAdmitadCookie = (value: string, host: string) => {
  Cookies.set(
    'admitad_uid',
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const removeAdmitadCookie = (host: string) => {
  Cookies.remove(
    'admitad_uid',
    {
      domain: `.${host}`,
    },
  );
};

const setSalesDoublerCookie = (value: string, host: string) => {
  Cookies.set(
    'sd_uid',
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const removeSalesDoublerCookie = (host: string) => {
  Cookies.remove(
    'sd_uid',
    {
      domain: `.${host}`,
    },
  );
};
