import { useServices } from "..";
import { ThemeOld } from "../../styles/theme-old";

const useThemeOld = (): ThemeOld => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useThemeOld');
  }

  return services.themeOld;
};

export default useThemeOld;
