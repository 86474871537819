import React from 'react';
import getStyles from './Skeleton.styles';

const Skeleton = () => {
  const styles = getStyles();

  return (
    <div className='Skeleton'>
      <style jsx>{ styles }</style>
    </div>
  );
};

export default Skeleton;
