import cn from 'classnames';
import { Fragment } from 'react';
import useThemeOld from 'src/services/theme/useThemeOld';
import getStyles from './FormLabel.styles';

export interface FormLabelProps {
  labelText?: string;
  htmlFor: string;
  isAlignedLeft?: boolean;
  withRequiredMark?: boolean;
}

export default function FormLabel(props: FormLabelProps) {
  const { htmlFor, labelText = '', isAlignedLeft, withRequiredMark } = props;

  const theme = useThemeOld();
  const styles = getStyles(theme);

  if (!htmlFor) {
    console.error(`htmlFor of label '${labelText}' is empty`);
  }

  return (
    <Fragment>
      <label
        className={cn('FormLabel', {
          FormLabel_aligned: isAlignedLeft,
        })}
        htmlFor={htmlFor}
      >
        {labelText}
        {withRequiredMark && (
          <span className='FormLabel__requiredMark'> *</span>
        )}
      </label>

      <style jsx>{styles}</style>
    </Fragment>
  );
}
