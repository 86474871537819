import convertTimeWindowToString from 'src/modules/retails/helpers/convertTimeWindowToString';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';

import { Delivery } from '../../delivery/types';
import { TimeWindow } from 'src/api/types';

const getTimeWindowText = (
  timeWindow: TimeWindow | null,
  deliveryType: Delivery['type'] | null,
  localize: LocalizeFunc,
): string | null => {
  if (deliveryType === null) {
    return null;
  }

  if (!timeWindow) {
    if (deliveryType === 'pickup') {
      return localize('retail_unavailable_slots_pickup_text');
    }

    return localize('retail_unavailable_slots_text');
  } else {

    return convertTimeWindowToString(timeWindow, deliveryType, localize);
  }
};

export default getTimeWindowText;
