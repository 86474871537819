import { useState } from 'react';
import { IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3';
import useForgotLogin from 'src/modules/authorizationModal/hook/useForgotLogin';
import useModals from 'src/modules/modals/useModals';
import ConfirmPhoneByCode from '../../../../components/ConfirmPhoneByCode/ConfirmPhoneByCode';
import EnterPhoneForm from '../../../../components/EnterPhoneForm/EnterPhoneForm';
import GeneralModal from '../../../../components/GeneralModal/GeneralModal';
import { useLocalize } from '../../../../services/localize/useLocalize';
import withRecaptcha from '../../hook/withRecaptcha';
import getStyles from './ForgotLoginModal.styles';
import NewPasswordForm from './NewPasswordForm';

export interface ForgotLoginModalProps {
  googleReCaptchaProps?: IGoogleReCaptchaConsumerProps;
}

const titleKeyMapBySteps = {
  1: 'account.title.password_recovery',
  2: 'account.phone_approve',
  3: 'general.create_new_password',
};

const descriptionKeyMapBySteps = {
  1: 'account.text.password_recovery',
  2: null,
  3: 'account_recoveryPassword_disclaimer',
};

const ForgotLoginModal = (props: ForgotLoginModalProps) => {
  const { googleReCaptchaProps } = props;
  const { closeModal } = useModals();

  const styles = getStyles();

  const localize = useLocalize();

  const {
    fields,
    step,
    handleChangeStep,
    handleFieldChange,
    handleSubmitFirstStep,
    handleSubmitSecondStep,
    handleSubmitThirdStepStep,
    isFetching,
    requestErrors,
    retryTimeout,
  } = useForgotLogin(googleReCaptchaProps);

  const [formattedPhone, setFormattedPhone] = useState<string>(fields.phone);

   const handleChangePhone = (phone: string, formattedPhone: string) => {
    handleFieldChange('phone', phone);
    setFormattedPhone(formattedPhone);
  };

  const activeTitleKey = titleKeyMapBySteps[step];
  const activeDescriptionKey = descriptionKeyMapBySteps[step];

  return (
    <GeneralModal
      onClose={() => closeModal('forgotLoginModal')}
      isOpen={true}
      withCloseButton={true}
      isFetching={isFetching}
    >
      <div
        className='ForgotLoginModal'
        data-testid='ForgotLoginModal'
        data-marker-popup='Forgot Login Modal'
      >
        {activeTitleKey && (
          <div className='ForgotLoginModal__title'>
            <span>{localize(activeTitleKey)}</span>
          </div>
        )}

        {activeDescriptionKey && (
          <div className='ForgotLoginModal__description'>
            <span>{localize(activeDescriptionKey)}</span>
          </div>
        )}

        {step === 1 && (
          <EnterPhoneForm
            phone={fields.phone}
            onChange={handleChangePhone}
            onSubmit={handleSubmitFirstStep}
            error={requestErrors[0]}
            buttonText={localize('account.btn.send_new_password')}
          />
        )}

        {step === 2 && (
          <ConfirmPhoneByCode
            phone={formattedPhone}
            onStepBack={() => handleChangeStep(1)}
            onRepeat={handleSubmitFirstStep}
            onComplete={handleSubmitSecondStep}
            retryAfterSeconds={retryTimeout}
            errors={requestErrors}
          />
        )}
        {step === 3 && (
          <NewPasswordForm
            onSubmit={handleSubmitThirdStepStep}
            onChangeField={handleFieldChange}
            fields={{
              new_password: fields.new_password,
              new_password_confirm: fields.new_password_confirm,
            }}
          />
        )}
      </div>

      <style jsx>{styles}</style>
    </GeneralModal>
  );
};

export default withRecaptcha(ForgotLoginModal);
