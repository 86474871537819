
import css from 'styled-jsx/css';
import typography from '../../styles/typography';

export const getStyles = () => {
  return css`
    .ErrorMessage {
      ${typography.error}
      margin: 0;
    }
  `;
};
