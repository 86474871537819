import { fetcher } from 'src/api/fetch';

interface RequestParams {
  phone: string;
  token?: string;
}

const requestPostUserPasswordRecovery = async (params: RequestParams) => {
  const bodyData = JSON.stringify({
    login: params.phone,
    captcha_token: params.token,
  });

  const response = await fetcher(`/user/password_recovery/`, {
    method: 'POST',
    credentials: 'include',
    body: bodyData,
  });

  return response;
};

export default requestPostUserPasswordRecovery;
