import { KeyboardEventHandler } from 'react';
import { FieldKeys } from '../../modules/account/hooks/useChangePassword/useChangePassword';
import useLocalize from '../../services/localize/useLocalize';
import Button from 'src/ui-kit/Button';
import PasswordInput from '../../UIKitOld/PasswordInput/PasswordInput';

export interface ChangePasswordFormProps {
  fields: {
    [key: string]: string;
  };
  fieldsErrorMap: {
    [key: string]: string;
  };
  onSubmit: () => void;
  onFieldChange: (field: string, newValue: string) => void;
  submitIsDisabled: boolean;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { fields, onSubmit, onFieldChange, submitIsDisabled, fieldsErrorMap } = props;

  const localize = useLocalize();

  const handleEnter: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter' && !submitIsDisabled) {
      onSubmit();
    }
  };

  return (
    <div
      className='ChangePasswordForm'
      data-marker-popup='Change the password for login'
      data-testid='ChangePasswordForm'
      onKeyDown={handleEnter}
    >
      <div className="ChangePasswordForm__form">
        {Object.keys(fields).map((field: FieldKeys) => (
          <div className="ChangePasswordForm__input" key={field}>
            <PasswordInput
              label={localize(`account.${field}`)}
              id={field}
              dataMarker={field}
              value={fields[field]}
              onChange={(e) => onFieldChange(field, e.target.value)}
              error={fieldsErrorMap[field]}
            />
          </div>
        ))}
      </div>

      <div className="ChangePasswordForm__button">
        <Button
          variant="primarySmall"
          disabled={submitIsDisabled}
          onClick={onSubmit}
          dataMarker='Submit'
        >
          {localize('account.change_password_button')}
        </Button>
      </div>
      <style jsx>{`
        .ChangePasswordForm__form {
          margin-bottom: 32px;
        }
        .ChangePasswordForm__input {
          margin-bottom: 14px;
        }
      `}</style>
    </div>
  );
};

export default ChangePasswordForm;
