import React, { Fragment } from 'react';

const CenteringComponent = (props) => {
  return (
    <Fragment>
      <div className='CenteringComponent'>
        {props.children}
      </div>
      <style jsx>{`
        .CenteringComponent {
          max-width: ${props.width ? props.width : 1150}px;
          margin: 0 auto;
        }
      `}</style>
    </Fragment>
  );
};

export default CenteringComponent;