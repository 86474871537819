import cn from 'classnames';
import Skeleton from 'src/components/Loaders/Skeleton/Skeleton';
import FlexList from '../../../../components/FlexList/FlexList';
import getStyles from './RetailsInfoSkeleton.styles';

interface RetailsInfoSkeletonProps {
  size: 'large' | 'medium' | 'small';
}

const RetailsInfoSkeleton = (props: RetailsInfoSkeletonProps) => {
  const { size } = props;
  const styles = getStyles();
  const numberOfItems = size === 'large' ? 9 : (size === 'medium' ? 6 : 3);
  const data = new Array(numberOfItems).fill(null).map((_, i) => ({ id: String(i) }));

  return (
    <div className={ cn('RetailsInfoSkeleton', {
      'RetailsInfoSkeleton_large': size === 'large',
      'RetailsInfoSkeleton_medium': size === 'medium',
      'RetailsInfoSkeleton_small': size === 'small',
    }) }>
      <FlexList
        size={ size }
        data={ data }
        renderItem={ () =>
          <div className='RetailsInfoSkeleton__item'>
            <Skeleton/>
          </div>
        }
      />
      <style jsx>{ styles }</style>
    </div>
  );
};

export default RetailsInfoSkeleton;
